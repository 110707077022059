import {
  Flex,
  Box,
  Heading,
  Text,
  MenuButton,
  Menu,
  MenuItem,
  MenuList,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { MatrixHighlightHelper } from '@texas/components/shared/matrix/MatrixHighlightHelper';
import {
  AxisType,
  compositionGroupMatrixApi,
  MatrixAxis,
} from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { MutableRefObject, useEffect, useRef } from 'react';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { DimensionGroupContainer } from '../../../../../shared/composition/dimension/DimensionGroupContainer';
import { useDraggable } from 'react-use-draggable-scroll';
import { CompositionName } from '../../shared/CompositionName';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { useTranslation } from 'react-i18next';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { SpinnerWithLabel } from '@texas/components/shared/SpinnerWithLabel';
import { DimensionValuesContainer } from '@texas/components/shared/composition/dimension/DimensionValuesContainer';
import { useSetDimensionValueEvent } from '@texas/components/shared/compositionGroup/useSetDimensionValueEvent';

export function MatrixSizes({
  colorAxis,
  compositionGroupId,
  onAnyValueChanged,
}: {
  colorAxis: MatrixAxis;
  compositionGroupId: number;
  onAnyValueChanged?: (nrOfZeroDimensions: number) => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { data, refetch, loading, set } = useApiResource(
    compositionGroupMatrixApi.getAxisCells,
  );

  useEffect(() => {
    refetch(compositionGroupId, AxisType.Sizes);
  }, [compositionGroupId, refetch]);

  const ref = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);

  useSignalSubscriptionEvents({
    triggerOnEvent: () => {
      refetch(compositionGroupId, AxisType.Sizes);
    },
    signalEvents: [
      compositionEvents.onDimensionGroupSet,
      compositionEvents.onRename,
      compositionEvents.onAdd,
      compositionEvents.onRestored,
      compositionEvents.onArchived,
      compositionEvents.onCopyDimensionValuesToAll,
    ],
  });

  useSetDimensionValueEvent(set);

  useEffect(() => {
    if (!data) return;
    const anyValueDataZero =
      ref.current?.querySelectorAll('* [data-value="0"]');

    onAnyValueChanged?.(anyValueDataZero?.length ?? 0);
  }, [data, onAnyValueChanged]);

  useEffect(() => {
    return compositionEvents.onDimensionValueChanged.sub(() => {
      const anyValueDataZero =
        ref.current?.querySelectorAll('* [data-value="0"]');

      onAnyValueChanged?.(anyValueDataZero?.length ?? 0);
    });
  }, [onAnyValueChanged]);

  const { request: copyRequest, loading: copyLoading } = useApiRequest(
    compositionGroupMatrixApi.setMatrixCellsDimensions,
  );
  const performCopy = async (id: number, index: number) =>
    await request(
      copyRequest,
      [id, index],
      () => {
        toast({
          title: t('general.copied'),
          status: 'success',
          isClosable: true,
        });
        compositionEvents.onCopyDimensionValuesToAll.dispatch();
      },
      () => {
        toast({
          title: t('general.actionFailed'),
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Flex flexDir="column" gap={4}>
      <Flex gap={4} align="center">
        {ensureEnumNumber(MatrixAxis, colorAxis) === MatrixAxis.Columns ? (
          <>
            <MatrixHighlightHelper zone="rows" />
            <Box>
              <Heading fontSize="lg">{t('matrix.rows')}</Heading>
              <Text>{t('matrix.dimensionDesc')}</Text>
            </Box>
          </>
        ) : (
          <>
            <MatrixHighlightHelper zone="columns" />
            <Box>
              <Heading fontSize="lg">{t('matrix.columns')}</Heading>
              <Text>{t('matrix.dimensionDesc')}</Text>
            </Box>
          </>
        )}
      </Flex>
      {copyLoading && <SpinnerWithLabel label={t('general.copyingData')} />}
      {loading && <SpinnerWithLabel label={t('general.loadingData')} />}
      <Flex gap={2} overflowX="auto" ref={ref} {...events} pb={6}>
        {data?.map((x) => {
          return (
            <Component
              loading={copyLoading || loading}
              onCopy={async (id, index) => await performCopy(id, index)}
              key={x.id}
              group={x}
              refetch={() => refetch(compositionGroupId, AxisType.Sizes)}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

function Component({
  group,
  refetch,
  onCopy,
  loading,
}: {
  group: CompositionGroup;
  refetch: () => void;
  onCopy: (id: number, index: number) => Promise<void>;
  loading: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Flex gap={2} flexDir="column" h="fit-content" minW="200px" px={2}>
      <Heading fontSize="xl">
        {ensureEnumNumber(MatrixAxis, group.colorAxis) === MatrixAxis.Columns
          ? group.matrixNodeYValue
          : group.matrixNodeXValue}
      </Heading>

      {group.compositions.map((x, i) => {
        return (
          <Flex key={x.id} flexDir="column" gap={1}>
            <Flex alignItems="center" justifyContent="space-between">
              <CompositionName note={x.note} index={i + 1} />
              <Menu>
                <MenuButton
                  as={IconButton}
                  variant="ghost"
                  isLoading={loading}
                  size="sm"
                  icon={<Icons.DotsHorizontal boxSize={defaultIconSize} />}
                />
                <MenuList>
                  <MenuItem
                    onClick={async () => await onCopy(group.id, i)}
                    icon={<Icons.DotsGrid />}
                  >
                    {t('composition.copyDimensionsToAll')}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Box
              animation={fadeInScaleAnimation()}
              bg="texas.bg.900"
              _light={{ bg: 'gray.50' }}
              padding={2}
              borderRadius="md"
            >
              {!x.dimension && <Text variant="sub">{t('general.noData')}</Text>}
              <DimensionGroupContainer
                mode="only-values"
                composition={x}
                onGroupChange={(_) => {
                  refetch();
                }}
              >
                <DimensionValuesContainer composition={x} groupId={group.id} />
              </DimensionGroupContainer>
            </Box>
          </Flex>
        );
      })}
    </Flex>
  );
}
