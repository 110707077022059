import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { Icons } from './Icons';
import { useTranslation } from 'react-i18next';

export function EditComponent({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  if (!onClick) return <>{children}</>;

  return (
    <Box
      pos="relative"
      sx={{
        ':hover *[data-hover-id="edit"]': {
          opacity: 1,
          transform: 'scale(1)',
        },
      }}
    >
      {children}
      <Box
        pos="absolute"
        right={-2}
        top={-3}
        transition="transform 200ms ease, opacity 200ms ease"
        opacity={0}
        transform="scale(0.9)"
        data-hover-id="edit"
      >
        <Tooltip label={t('general.clickToEdit')}>
          <IconButton
            bg="white"
            color="texas.bg.900"
            _hover={{ opacity: 1, bg: 'gray.100' }}
            variant="solid"
            size="sm"
            onClick={onClick}
            aria-label="edit"
            icon={<Icons.Pencil />}
          />
        </Tooltip>
      </Box>
    </Box>
  );
}
