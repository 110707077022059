import { Box, SimpleGrid, GridItem } from '@chakra-ui/react';
import React, { useRef, useMemo, useEffect } from 'react';
import { VirtuosoGridHandle, VirtuosoGrid } from 'react-virtuoso';
import { ColorComponent } from './ColorComponent';
import { Color } from './ColorPicker';

export function ColorGrid({
  colors,
  searchTerm,
  pickedColors,
  colorPicked,
  columns,
}: {
  colors: Color[];
  searchTerm: RegExp;
  pickedColors: string[];
  colorPicked: (code: string, hex: string, name: string) => void;
  columns: number;
}) {
  const ref = useRef<VirtuosoGridHandle>(null);
  const filteredColors = useMemo(() => {
    const colorsMatchingSearchTerm = colors.filter(
      (color) =>
        color.name.toLowerCase().match(searchTerm) ??
        color.code.toLowerCase().match(searchTerm),
    );

    return colorsMatchingSearchTerm;
  }, [searchTerm, colors]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTo({ behavior: 'smooth', top: 0 });
  }, [searchTerm]);

  return (
    <Box position="relative" minH={20}>
      <VirtuosoGrid
        ref={ref}
        style={{ height: '500px' }}
        totalCount={filteredColors.length}
        components={{
          List: React.forwardRef(function List(props, listRef) {
            return (
              <SimpleGrid
                ref={listRef}
                columns={columns}
                columnGap={1}
                rowGap={1}
                m={2}
                {...props}
              />
            );
          }),
          Item: ({ ...props }) => <GridItem {...props} overflow="hidden" />,
        }}
        itemContent={(index) => {
          const color = filteredColors[index];
          return (
            <ColorComponent
              color={color}
              selected={pickedColors.some((x) => x === color.code)}
              onSelect={() => {
                colorPicked(color.code, color.hex ?? '', color.name);
              }}
            />
          );
        }}
      />
    </Box>
  );
}
