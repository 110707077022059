import { useToast } from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { compositionApi } from '@texas/api/endpoints/compositionApi';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ServerError } from '@texas/types';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { productApprovalEvents } from '@texas/components/article/productApproval/events';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';
import { DimensionGroupMode } from '@texas/components/shared/composition/dimension/DimensionGroupContainer';
import { MaterialMode } from '../composition/DetailedCompositon';

export interface CompositionLocationData {
  variantId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

type ComponentState = 'hidden';

export interface CompositionGroupComponentSettings {
  expander?: ComponentState;
  compositionGroupDots?: ComponentState;
  dimensionMode?: DimensionGroupMode;
  characterEdit?: ComponentState;
  addMaterials?: ComponentState;
  materialsMode?: MaterialMode;
  removeMaterials?: ComponentState;
  compositionArchiveRestore?: ComponentState;
  editImage?: ComponentState;
  paStates?: ComponentState;
  expandedDefault?: boolean;
  colors?: ComponentState;
}

export function useCompositionGroup({
  compositionGroupId,
  variantId,
  cellX,
  cellY,
  supplierId,
  branchId,
  articleNodeGroup,
  onlyArchived,
  componentSettings,
}: {
  compositionGroupId: number;
  variantId: number;
  cellX?: number;
  cellY?: number;
  supplierId?: number;
  branchId?: number;
  articleNodeGroup?: ArticleNodeGroup;
  onlyArchived?: boolean;
  componentSettings?: CompositionGroupComponentSettings;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const locationData = useRef<CompositionLocationData>({
    variantId,
    cellX,
    cellY,
    supplierId,
    branchId,
  });

  const [isPerformingApiCall, setIsPerformingApiCall] = useState(false);
  const {
    data: group,
    refetch: refetchGroupFromId,
    loading: groupLoading,
    set: setGroup,
  } = useApiResource(compositionGroupApi.getGroup);

  const refetchGroup = useCallback(() => {
    refetchGroupFromId(
      compositionGroupId,
      onlyArchived ? { includeArchived: true } : undefined,
    );
  }, [compositionGroupId, onlyArchived, refetchGroupFromId]);

  useSignalSubscriptionEvents({
    triggerOnEvent: refetchGroup,
    signalEvents: [
      productApprovalEvents.productApprovalsCreated,
      compositionEvents.onMaterialChanged,
      compositionEvents.onMaterialAdded,
      compositionEvents.onMaterialRemoved,
    ],
  });

  useEffect(() => {
    refetchGroup();
  }, [refetchGroup]);

  const createGroupRequest = async () => {
    setIsPerformingApiCall(true);
    await request(
      compositionGroupApi.addGroup,
      [{ variantId, cellX, cellY, supplierId, branchId }],
      (response) => {
        setIsPerformingApiCall(false);
        setGroup(response);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const restoreGroupRequest = async () => {
    setIsPerformingApiCall(true);
    await request(
      compositionGroupApi.restore,
      [compositionGroupId],
      (response) => {
        setIsPerformingApiCall(false);
        setGroup(response);
        toast({
          title: t('general.restored'),
          status: 'success',
          isClosable: true,
        });
        compositionGroupEvents.onRestored.dispatch();
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.restoreFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const archiveGroupRequest = async () => {
    setIsPerformingApiCall(true);
    await request(
      compositionGroupApi.archive,
      [compositionGroupId],
      (response) => {
        setIsPerformingApiCall(false);
        setGroup(response);
        toast({
          title: t('general.successfullyArchived'),
          status: 'success',
          isClosable: true,
        });
        compositionGroupEvents.onArchived.dispatch();
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.archiveFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const addCompositionToGroupRequest = async () => {
    setIsPerformingApiCall(true);
    await request(
      compositionApi.addComposition,
      [compositionGroupId],
      (response) => {
        setIsPerformingApiCall(false);
        setGroup((r) => ({
          ...r!,
          compositions: [...r!.compositions, response],
        }));
        compositionEvents.onAdd.dispatch();
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const archiveCompositionRequest = async (id: number) => {
    setIsPerformingApiCall(true);
    await request(
      compositionApi.archiveComposition,
      [id],
      (response) => {
        setIsPerformingApiCall(false);
        compositionEvents.onArchived.dispatch();
        setGroup((r) => ({
          ...r!,
          hasArchivedCompositions: true,
          compositions: r!.compositions.map((x) => {
            if (x.id === response.id) return response;
            return x;
          }),
        }));
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.archiveFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const restoreCompositionRequest = async (id: number) => {
    setIsPerformingApiCall(true);
    await request(
      compositionApi.restoreComposition,
      [id],
      (response) => {
        setIsPerformingApiCall(false);
        compositionEvents.onRestored.dispatch();
        setGroup((r) => ({
          ...r!,
          hasArchivedCompositions: r!.compositions.some(
            (c) => c.id !== response.id && c.archived,
          ),
          compositions: r!.compositions.map((x) => {
            if (x.id === response.id) return response;
            return x;
          }),
        }));
        toast({
          title: t('general.restored'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.restoreFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  const copyCompositionGroupRequest = async (
    fromId: number,
    basedOnId: number | undefined,
    replaceId: number | undefined,
  ) => {
    setIsPerformingApiCall(true);
    await request(
      compositionGroupApi.copyGroup,
      [
        fromId,
        {
          ...locationData.current,
          replaceCompositionGroupId: replaceId,
          basedOnId,
        },
      ],
      (_) => {
        refetchGroup();
      },
      (error: ServerError) => {
        toast({
          title: t('general.copyFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
    setIsPerformingApiCall(false);
  };

  const compositions = () => {
    const c = group?.compositions ?? [];

    return onlyArchived
      ? c.filter((x) => x.archived)
      : c.filter((x) => !x.archived);
  };

  const productApprovalsCount = (state: ProductApprovalState) => {
    return (
      group?.productApprovals?.filter(
        (x) => convertToEnum(ProductApprovalState, x.state) == state,
      ).length ?? 0
    );
  };

  const anyProductApproval = (group?.productApprovals?.length ?? 0) > 0;

  return {
    group: group,
    compositions,
    locationData,
    loading: groupLoading || isPerformingApiCall,
    articleNodeGroup,
    createGroupRequest,
    restoreGroupRequest,
    archiveGroupRequest,
    addCompositionToGroupRequest,
    archiveCompositionRequest,
    restoreCompositionRequest,
    componentSettings,
    productApprovalsCount,
    copyCompositionGroupRequest,
    anyProductApproval,
    setGroup,
  };
}
