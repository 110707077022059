import { useApiResource } from '@texas/api/hooks/useApiResource';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  Collapse,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { AddSupplier } from './add/AddSupplier';
import {
  Branch,
  VariantBranchSupplier,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { MirrorProps, SupplierCell } from './SupplierCell';
import React from 'react';
import { DisableSupplierDialog } from './DisableSupplierDialog';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import gridCellStyle from '@texas/resources/theme/styles/gridCell';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { useTranslation } from 'react-i18next';
import { EnableSupplierDialog } from './EnableSupplierDialog';
import { ChooseContactsModal } from './ChooseContactsModal';
import { request } from '@texas/utils/helpers/httpHelpers';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { alphabet } from '@texas/utils/helpers/commonHelpers';
import { TFunction } from 'i18next';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';

interface LinkProps {
  branchId: number;
  supplierVariantId: number;
}

export function VariantSuppliers({ variantId }: { variantId: number }) {
  const { t } = useTranslation();
  const {
    data: suppliersOverview,
    refetch,
    loading,
  } = useApiResource(variantsApi.getVariantBranchSuppliers);

  const [mirrorData, setMirrorData] = useState<LinkProps | null>(null);

  useEffect(() => {
    refetch(variantId, true);
  }, [variantId, refetch]);

  const [addSupplierData, setAddSupplierData] = useState<{
    branchId: number;
    branchName: string;
  } | null>(null);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    value: toMirror,
    isOpen: isOpenToMirror,
    onClose: onCloseToMirror,
    onOpen: onOpenToMirror,
  } = useValueDisclosure<number>();
  const {
    value: removeMirrorValue,
    isOpen: isOpenRemoveMirror,
    onClose: onCloseRemoveMirror,
    onOpen: onOpenRemoveMirror,
  } = useValueDisclosure<number>();

  const updateSupplierContactsRequest = (
    variantSupplierId: number,
    ids: number[],
  ) =>
    request(
      variantsApi.updateSupplierContacts,
      [variantSupplierId, { contactIds: ids }],
      () => {
        refetch(variantId, true);
      },
    );

  const {
    value: disableSupplierValue,
    isOpen: isDisableSupplierOpen,
    onOpen: onDisableSupplierOpen,
    onClose: onDisableSupplierClose,
  } = useValueDisclosure<VariantBranchSupplier>();

  const {
    value: enableSupplierValue,
    isOpen: isEnableSupplierOpen,
    onOpen: onEnableSupplierOpen,
    onClose: onEnableSupplierClose,
  } = useValueDisclosure<VariantBranchSupplier>();

  const {
    value: editContactsValue,
    isOpen: isEditContactsOpen,
    onClose: onEditContactsClose,
    onOpen: onEditContactsOpen,
  } = useValueDisclosure<{ supplierId: number; variantSupplierId: number }>();

  const supplierLinks = useMemo(() => {
    const data: Record<number, { value: string } | undefined> = {};
    let i = 0;

    suppliersOverview?.suppliers.forEach((s) => {
      if (s.mirroringSupplierIds.length === 0) return;

      data[s.id] = {
        value: alphabet.charAt(i),
      };

      i++;
    });

    return data;
  }, [suppliersOverview?.suppliers]);

  useEffect(() => {
    function onEscape(e: KeyboardEvent) {
      if (e.key === 'Escape' && !isOpenToMirror) {
        setMirrorData(null);
      }
    }
    document.addEventListener('keyup', onEscape);

    return () => document.removeEventListener('keyup', onEscape);
  }, [isOpenToMirror]);

  return (
    <BodyOverride>
      {disableSupplierValue && (
        <DisableSupplierDialog
          onDisable={() => refetch(variantId, true)}
          isOpen={isDisableSupplierOpen}
          onClose={onDisableSupplierClose}
          supplier={disableSupplierValue}
        />
      )}
      {enableSupplierValue && (
        <EnableSupplierDialog
          onEnable={() => refetch(variantId, true)}
          isOpen={isEnableSupplierOpen}
          onClose={onEnableSupplierClose}
          supplier={enableSupplierValue}
        />
      )}

      <Drawer isOpen={isOpen} onClose={onClose} size="sm">
        <DrawerOverlay />

        <DrawerContent bg="texas.bg.900" _light={{ bg: 'gray.10' }}>
          <DrawerCloseButton />
          {addSupplierData && (
            <AddSupplier
              variantId={variantId}
              branchId={addSupplierData.branchId}
              branchName={addSupplierData.branchName}
              onAdd={() => {
                refetch(variantId, true);
              }}
            />
          )}
        </DrawerContent>
      </Drawer>

      <Collapse in={mirrorData !== null}>
        <Box>
          <Heading>{t('variant.supplier.mirrorMode')}</Heading>
          <Text>{t('variant.supplier.mirrorModeDesc')}</Text>
          <Button
            onClick={() => setMirrorData(null)}
            borderRadius="md"
            mb="4"
            mt={2}
            rightIcon={
              <Icons.keyboardEscape opacity={0.7} boxSize={defaultIconSize} />
            }
          >
            {t('general.discard')}
          </Button>
        </Box>
      </Collapse>

      <Card variant="dark" overflow="scroll">
        <CardBody>
          {loading && (
            <Box minH="100px">
              <LoadingOverlayV2
                loaded={!loading}
                label={t('general.loading')}
              />
            </Box>
          )}
          <Grid autoFlow="row" autoColumns="240px" gap={4}>
            {suppliersOverview?.branches.map((b, i) => {
              const colIndex = i + 1;
              return (
                <React.Fragment key={b.id}>
                  <GridItem
                    colStart={colIndex}
                    rowStart={1}
                    key={b.id}
                    pos="sticky"
                    top={0}
                    bg="texas.bg.dark"
                    _light={{ bg: 'white' }}
                  >
                    <Flex flexDir="column" mb={2}>
                      <Text variant="header">{b.identifier}</Text>
                      <Text variant="sub" pb={2}>
                        {b.name}
                      </Text>
                      <Button
                        variant="texas-outline"
                        borderStyle="dashed"
                        isDisabled={mirrorData !== null}
                        onClick={() => {
                          setAddSupplierData({
                            branchId: b.id,
                            branchName: b.name,
                          });
                          onOpen();
                        }}
                        borderRadius="md"
                      >
                        {t('suppliers.addSupplier')}
                      </Button>
                    </Flex>
                  </GridItem>

                  {suppliersOverview.suppliers
                    .filter((x) => x.branchId === b.id)
                    .map((x, rowIndex) => {
                      const {
                        tooltipLabel,
                        isMirrorSelf,
                        mirrorDisabled,
                        mirrorValue,
                      } = mirrorState(t, mirrorData, x, b, supplierLinks);

                      return (
                        <Tooltip key={x.supplierId} label={tooltipLabel}>
                          <GridItem
                            {...gridCellStyle(x.disabled)}
                            transition="opacity 200ms ease, transform 200ms ease"
                            rowStart={2 + rowIndex + 1}
                            colStart={colIndex}
                            border={isMirrorSelf ? '1px solid white' : ''}
                            {...(mirrorDisabled
                              ? {
                                  opacity: 0.5,
                                  transform: 'scale(0.95)',
                                }
                              : null)}
                            _hover={{
                              ...(mirrorData && !mirrorDisabled && !isMirrorSelf
                                ? {
                                    transform: 'scale(1.06)',
                                    cursor: 'pointer',
                                  }
                                : undefined),
                            }}
                          >
                            <SupplierCell
                              mirrorValue={mirrorValue}
                              mirrorModeOnClick={
                                mirrorData
                                  ? () => {
                                      if (mirrorDisabled || isMirrorSelf)
                                        return;
                                      onOpenToMirror(x.id);
                                    }
                                  : undefined
                              }
                              onMirrorClick={() => {
                                if (x.mirrorSupplierId) {
                                  onOpenRemoveMirror(x.id);
                                  return;
                                }
                                setMirrorData({
                                  branchId: x.branchId,
                                  supplierVariantId: x.id,
                                });
                              }}
                              supplier={x}
                              onDisable={() => {
                                onDisableSupplierOpen(x);
                              }}
                              onEnable={() => {
                                onEnableSupplierOpen(x);
                              }}
                              onChooseContacts={() =>
                                onEditContactsOpen({
                                  supplierId: x.supplierId,
                                  variantSupplierId: x.id,
                                })
                              }
                            />
                          </GridItem>
                        </Tooltip>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </Grid>
        </CardBody>
      </Card>
      {editContactsValue && (
        <ChooseContactsModal
          acceptLabel={t('suppliers.updateContacts')}
          variantSupplierId={editContactsValue.variantSupplierId}
          supplierId={editContactsValue.supplierId}
          onContactsSelect={(ids) => {
            updateSupplierContactsRequest(
              editContactsValue.variantSupplierId,
              ids,
            );
            onEditContactsClose();
          }}
          isOpen={isEditContactsOpen}
          onClose={onEditContactsClose}
        />
      )}
      {toMirror && mirrorData && (
        <VerifyDialogWithRequest
          headerTitle={t('variant.supplier.mirrorEnableTitle')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={variantsApi.mirrorSupplier}
          args={[mirrorData.supplierVariantId, toMirror]}
          isOpen={isOpenToMirror}
          onClose={onCloseToMirror}
          onPerformed={() => {
            refetch(variantId, true);
            setMirrorData(null);
          }}
          onSuccessTitle={t('variant.supplier.mirrorSuccess')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
      )}
      {removeMirrorValue && (
        <VerifyDialogWithRequest
          headerTitle={t('variant.supplier.mirrorDisableTitle')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={variantsApi.removeMirror}
          args={[removeMirrorValue]}
          isOpen={isOpenRemoveMirror}
          onClose={onCloseRemoveMirror}
          onPerformed={() => {
            refetch(variantId, true);
          }}
          onSuccessTitle={t('variant.supplier.mirrorDisabledSuccess')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
      )}
    </BodyOverride>
  );
}

function mirrorState(
  t: TFunction,
  mirrorData: LinkProps | null,
  supplier: VariantBranchSupplier,
  branch: Branch,
  supplierLinks: Record<number, { value: string } | undefined>,
) {
  const mirrorDisabled =
    (mirrorData?.branchId === branch.id &&
      mirrorData.supplierVariantId !== supplier.id) ||
    (mirrorData && supplier.mirrorSupplierId) ||
    supplier.disabled;
  const isMirrorSelf = mirrorData?.supplierVariantId === supplier.id;

  let tooltipLabel: string | undefined = undefined;
  const mirrorValue: MirrorProps | null = supplier.mirrorSupplierId
    ? {
        type: 'follower',
        value: supplierLinks[supplier.mirrorSupplierId]!.value,
      }
    : supplierLinks[supplier.id] !== undefined
    ? { type: 'main', value: supplierLinks[supplier.id]!.value }
    : null;

  if (mirrorData) {
    if (isMirrorSelf) {
      tooltipLabel = t('variant.supplier.notMirrorSelf');
    } else if (mirrorDisabled) {
      tooltipLabel = t('variant.supplier.notMirrorSupplier');
    } else {
      tooltipLabel = t('variant.supplier.mirrorSupplierDesc');
    }
  }

  return { mirrorDisabled, isMirrorSelf, mirrorValue, tooltipLabel };
}
