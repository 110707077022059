const textTheme = {
  variants: {
    header: {
      fontFamily: 'Barlow',
      fontWeight: 'bold',
      fontSize: '1.4em',
      color: 'white',
      _light: { color: 'texas.bg.900' },
    },
    'header-medium': {
      fontFamily: 'Barlow',
      fontWeight: 'medium',
      fontSize: '1.4em',
      _light: { color: 'texas.bg.900' },
    },
    'tab-header': {
      fontFamily: 'Barlow',
      fontWeight: 'bold',
      fontSize: '1.4em',
    },
    main: {
      color: 'white',
      fontWeight: 'bold',
      _light: {
        color: 'texas.bg.900',
      },
    },
    small: {
      fontSize: '0.9em',
      fontWeight: 'normal',
      color: 'gray.200',
      _light: { color: 'gray.400' },
    },
    sub: {
      color: 'gray.200',
      _light: { color: 'gray.400' },
    },
    subtitle: {
      fontSize: '12px',
      fontWeight: 'bold',
      color: 'gray.300',
      _light: {
        color: 'gray.400',
      },
    },
    note: {
      fontSize: 'xs',
      fontWeight: 'bold',
      color: 'yellow.400',
      _light: { color: 'orange.400' },
    },
    error: {
      color: 'texas.danger.100',
      _light: { color: 'red.600' },
    },
    navbar: {
      color: 'whiteAlpha.900',
    },
    'meta-field': {
      fontSize: '0.9em',
      fontWeight: 'bold',
      color: 'gray.100',
      _light: { color: 'gray.400' },
    },
    light: {
      color: 'gray.400',
      _light: { color: 'gray.600' },
    },
  },
};

export default textTheme;
