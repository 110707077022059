import {
  Box,
  Button,
  Flex,
  Heading,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Composition } from '@texas/api/endpoints/compositionApi';
import { metricShortName } from './translations';
import { useTranslation } from 'react-i18next';
import { DefaultDimensionsForm } from './DefaultDimensionForm';
import { MetadataList, MetadataListRow } from '../../MetadataList';
import { clientEndpoints } from '@texas/clientEndpoints';
import { compositionEvents } from '../events';
import { EditComponent } from '../../EditComponent';

export function DimensionValuesContainer({
  groupId,
  composition,
}: {
  groupId: number;
  composition: Composition;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!composition.dimension) {
    return null;
  }

  return (
    <>
      <Box>
        <EditComponent onClick={onOpen}>
          <MetadataList>
            {composition.dimension.dimensions.map((d) => {
              return (
                <MetadataListRow
                  key={d.id}
                  label={d.label}
                  data-value={d.value}
                >
                  {d.value} {metricShortName(d.metric)}
                </MetadataListRow>
              );
            })}
          </MetadataList>
        </EditComponent>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('composition.enterDimensions')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InnerModal composition={composition} groupId={groupId} />
          </ModalBody>

          <ModalFooter>
            <Button variant="texas-solid" onClick={onClose}>
              {t('general.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function InnerModal({
  composition,
  groupId,
}: {
  composition: Composition;
  groupId: number;
}) {
  return (
    <Flex gap={16}>
      {composition.dimension?.fileIdentifier && (
        <Box>
          <Heading fontSize="md">{composition.dimension.name}</Heading>
          <Text>{composition.dimension.description}</Text>
          <Img
            pt={4}
            src={clientEndpoints.previewImage(
              composition.dimension.fileIdentifier,
              412,
            )}
          />
        </Box>
      )}

      <Flex gap={2} pt={2} flexDir="column">
        {composition.dimension?.dimensions.map((d) => {
          return (
            <DefaultDimensionsForm
              key={d.id}
              dimension={d}
              compositionId={composition.id}
              onUpdate={(compositionId, dimensionId, value) =>
                compositionEvents.onDimensionValueChanged.dispatch({
                  groupId,
                  compositionId,
                  dimensionId,
                  value,
                })
              }
            />
          );
        })}
      </Flex>
    </Flex>
  );
}
