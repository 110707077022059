import { Box, Flex, Text, IconButton } from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel, {
  CarouselInternalState,
  SkipCallbackOptions,
} from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CompositionCarouselCard } from './CompositionCarouselCard';
import { CompositionGroupMenu } from './CompositionGroupMenu';
import { Icons } from '@texas/components/shared/Icons';
import { TemplateCard } from '../composition/TemplateCard';
import { range } from '@texas/utils/helpers/commonHelpers';
import {
  CompositionGroupComponentSettings,
  useCompositionGroup,
} from '../hooks/useCompositionGroup';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { CompositionGroupHeader } from './CompositionGroupHeader';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { useSetDimensionValueEvent } from '@texas/components/shared/compositionGroup/useSetDimensionValueEvent';

interface Props {
  customTitle?: string;
  variantId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
  articleNodeGroup?: ArticleNodeGroup;
  compositionGroupId: number;
  onLoaded?: (template: CompositionGroup | null) => void;
  onlyArchived?: boolean;
  componentSettings?: CompositionGroupComponentSettings;
}

export const GroupContext = createContext<ReturnType<
  typeof useCompositionGroup
> | null>(null);

export const compositionCardWidth = 380;

export function CompositionGroupView({
  customTitle,
  variantId,
  supplierId,
  branchId,
  cellX,
  cellY,
  articleNodeGroup,
  onLoaded,
  compositionGroupId,
  onlyArchived,
  componentSettings,
}: Props) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(
    componentSettings?.expandedDefault ?? false,
  );

  const carouselRef = useRef<Carousel>(null);

  const useGroup = useCompositionGroup({
    variantId,
    cellX,
    cellY,
    supplierId,
    branchId,
    compositionGroupId,
    articleNodeGroup,
    onlyArchived,
    componentSettings,
  });

  useSetDimensionValueEvent(useGroup.setGroup);

  useEffect(() => {
    if (!onLoaded || !useGroup.group) return;
    onLoaded(useGroup.group);
  }, [onLoaded, useGroup.group]);

  const visibleItems = expanded
    ? useGroup.compositions().length === 0
      ? 1
      : useGroup.compositions().length
    : 1;

  useEffect(() => {
    return compositionEvents.onRestored.sub(() => {
      carouselRef.current?.goToSlide(0);
    });
  }, []);

  return (
    <>
      <GroupContext.Provider value={useGroup}>
        <Flex flexDir="column" gap={2} w="fit-content" pos="relative">
          <Flex>
            <CompositionGroupHeader customTitle={customTitle} />
            {useGroup.group?.exists &&
              componentSettings?.compositionGroupDots !== 'hidden' && (
                <CompositionGroupMenu />
              )}
          </Flex>

          <LoadingOverlayV2 loaded={!useGroup.loading} />
          {useGroup.group?.exists && (
            <Box
              w={`${compositionCardWidth * visibleItems}px`}
              minH="400px"
              pos="relative"
            >
              <Carousel
                customButtonGroup={
                  <CarouselFooter
                    expanded={visibleItems !== 1}
                    toggleView={() => setExpanded((e) => !e)}
                  />
                }
                ref={carouselRef}
                partialVisible={true}
                arrows={false}
                draggable={false}
                containerClass="composition-carousel-list"
                responsive={{
                  desktop: {
                    breakpoint: { max: 4000, min: 0 },
                    items: visibleItems,
                    partialVisibilityGutter: 10,
                  },
                }}
              >
                {useGroup.compositions().length == 0 ? (
                  <TemplateCard exists={false}>
                    <Text m="auto" color="gray.400">
                      {t('composition.noComposition')}
                    </Text>
                  </TemplateCard>
                ) : (
                  useGroup.compositions().map((x) => {
                    return (
                      <CompositionCarouselCard key={x.id} composition={x} />
                    );
                  })
                )}
              </Carousel>
            </Box>
          )}
        </Flex>
      </GroupContext.Provider>
    </>
  );
}

function CarouselFooter({
  toggleView,
  expanded,
  carouselState,
  goToSlide,
}: {
  carouselState?: CarouselInternalState;
  toggleView: () => void;
  expanded: boolean;
  goToSlide?: (slideIndex: number, skipCallbacks?: SkipCallbackOptions) => void;
}) {
  const { componentSettings } = useContext(GroupContext)!;

  if (!carouselState || !goToSlide) return null;

  return (
    <Flex justifyContent="end" w="full" align="center" gap={1} py={2}>
      {carouselState.totalItems > 1 &&
        componentSettings?.expander !== 'hidden' && (
          <IconButton
            icon={expanded ? <Icons.ArrowCollapse /> : <Icons.ArrowExpand />}
            aria-label="expand"
            onClick={() => {
              goToSlide(0);
              toggleView();
            }}
            variant="ghost"
          />
        )}

      <Flex flexWrap="wrap">
        {range(
          0,
          Math.ceil(carouselState.totalItems / carouselState.slidesToShow) - 1,
        ).map((x) => {
          const icon =
            carouselState.currentSlide == x ? (
              <Icons.Circle />
            ) : (
              <Icons.CircleOutline />
            );
          return (
            <IconButton
              size="sm"
              key={x}
              icon={icon}
              aria-label="slide-button"
              onClick={() => goToSlide(x)}
              variant="ghost"
            />
          );
        })}
      </Flex>
    </Flex>
  );
}
