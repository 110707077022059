import {
  ProductApproval,
  ProductApprovalState,
} from '@texas/api/endpoints/productApprovalsApi';
import {
  MetadataList,
  MetadataListRow,
} from '@texas/components/shared/MetadataList';
import { useActiveSession } from '@texas/hooks/useSession';
import { useTranslation } from 'react-i18next';

export function PaMetadataList({
  state,
  pa,
}: {
  state: ProductApprovalState;
  pa: ProductApproval;
}) {
  const session = useActiveSession();
  const { t } = useTranslation();
  return (
    <MetadataList>
      <MetadataListRow
        label={
          state === ProductApprovalState.Accepted
            ? t('productApproval.acceptedBy')
            : state === ProductApprovalState.Pending
            ? t('productApproval.revertedBy')
            : t('productApproval.rejectedBy')
        }
      >
        {session.currentUser.name}
      </MetadataListRow>
      <MetadataListRow label={t('compositionGroup.version.supplierVersion')}>
        {t('compositionGroup.version.versionNr', {
          nr: pa.compositionGroupVersion,
        })}
      </MetadataListRow>
      <MetadataListRow label={t('general.option')}>
        {t('compositionGroup.option.optionNr', {
          nr: pa.compositionGroupBasedOnOption,
        })}
      </MetadataListRow>
      <MetadataListRow label={t('suppliers.supplier')}>
        {pa.supplierName}
      </MetadataListRow>
      <MetadataListRow label={t('general.branch')}>
        {pa.branchName}
      </MetadataListRow>
      <MetadataListRow label={t('variant.variant')}>
        {pa.variantName}
      </MetadataListRow>
      {pa.matrixNodeXId && (
        <MetadataListRow label={pa.matrixNodeXName}>
          {pa.matrixNodeXValue}
        </MetadataListRow>
      )}
      {pa.matrixNodeYId && (
        <MetadataListRow label={pa.matrixNodeYName}>
          {pa.matrixNodeYValue}
        </MetadataListRow>
      )}
    </MetadataList>
  );
}
