import {
  Box,
  Button,
  Flex,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Composition,
  compositionApi,
  CompositionMaterial,
  UpdateCompositionMaterialRequest,
} from '@texas/api/endpoints/compositionApi';
import { CompositionImage } from './CompositionImage';
import { Icons } from '@texas/components/shared/Icons';
import { CompositionName } from './CompositionName';
import { useTranslation } from 'react-i18next';
import { DimensionGroupContainer } from '../../../../shared/composition/dimension/DimensionGroupContainer';
import { WarningComponent } from '@texas/components/shared/WarningComponent';
import { CharacterContainer } from '@texas/components/shared/composition/character/CharacterContainer';
import { RenameCompositionModal } from '@texas/components/shared/composition/RenameCompositionModal';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { AddMaterial } from '@texas/components/shared/composition/material/AddMaterial';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { Character } from '@texas/components/shared/composition/character/character';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { CompositionMaterialView } from '@texas/components/shared/composition/material/CompositionMaterialView';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { UpdateCompositionMaterial } from '@texas/components/shared/composition/material/UpdateCompositionMaterial';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ServerError } from '@texas/types';
import { TFunction } from 'i18next';
import { DimensionValuesContainer } from '@texas/components/shared/composition/dimension/DimensionValuesContainer';

interface Props {
  groupHasAnyBasedOn: boolean;
  disableArchive: boolean;
  isMatrix: boolean;
  composition: Composition;
  groupId: number;
  index: number;
  onMaterialChanged: () => void;
}

export function CompositionComponent(props: Props) {
  const { t } = useTranslation();
  const {
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen,
  } = useDisclosure();
  const {
    isOpen: isRenameOpen,
    onClose: onRenameClose,
    onOpen: onRenameOpen,
  } = useDisclosure();
  const {
    isOpen: editIsOpen,
    value,
    onClose: editOnClose,
    onOpen: editOnOpen,
  } = useValueDisclosure<CompositionMaterial>();

  const toast = useToast();

  const { request: updateRequest } = useApiRequest(
    compositionApi.updateCompositionMaterial,
  );
  const onSubmit = async (data: UpdateCompositionMaterialRequest) =>
    await request(
      updateRequest,
      [value!.id, data],
      (_) => {
        compositionEvents.onMaterialChanged.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Box w="400px" animation={fadeInScaleAnimation()} role="group">
      <Flex gap={1} pb={2} align="end" px={1}>
        <CompositionName
          note={props.composition.note}
          index={props.index + 1}
        />
        <Flex
          gap={2}
          align="center"
          transition="opacity 200ms ease, padding 200ms ease"
          opacity={0}
          paddingLeft={0}
          _groupHover={{ opacity: 1, paddingLeft: 1 }}
        >
          <Button
            onClick={onRenameOpen}
            size="sm"
            variant="link"
            leftIcon={<Icons.NoteEdit />}
          >
            {t('general.rename')}
          </Button>
          <Tooltip
            label={
              props.groupHasAnyBasedOn
                ? t('compositionGroup.option.hasVersions')
                : undefined
            }
          >
            <Button
              size="sm"
              variant="link"
              color="red.200"
              isDisabled={props.groupHasAnyBasedOn}
              _light={{ color: 'red.400' }}
              onClick={onArchiveOpen}
              leftIcon={<Icons.Archive />}
            >
              {t('composition.archiveComposition')}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      <Box
        background="texas.bg.900"
        _light={{
          bg: 'white',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.35);',
        }}
        borderRadius="md"
        boxShadow="0px 0px 10px 0px rgba(0,0,0,0.75);"
      >
        <Box boxSize="400px" padding="4">
          <CompositionImage
            fileId={props.composition.fileId}
            compositionId={props.composition.id}
            folderId={props.composition.folderId}
          />
        </Box>
        <Flex p={2} flexDir="column" gap={4}>
          <Tooltip
            label={
              props.groupHasAnyBasedOn
                ? t('compositionGroup.option.hasVersions')
                : undefined
            }
          >
            <Box>
              <CharacterContainer
                character={convertToEnum(
                  Character,
                  props.composition.character,
                )}
                compositionId={props.composition.id}
                onChange={() => compositionEvents.onCharacterSet.dispatch()}
                mode={props.groupHasAnyBasedOn ? 'read-only' : undefined}
              />
            </Box>
          </Tooltip>
          <Tooltip
            label={
              props.groupHasAnyBasedOn
                ? t('compositionGroup.option.hasVersions')
                : undefined
            }
          >
            <Box>
              <WarningComponent
                warning={missingDimensionGroup(props.composition, t)}
                w="auto"
              >
                <DimensionGroupContainer
                  mode={props.isMatrix ? 'only-group' : undefined}
                  composition={props.composition}
                  onGroupChange={() =>
                    compositionEvents.onDimensionGroupSet.dispatch()
                  }
                >
                  <DimensionValuesContainer
                    composition={props.composition}
                    groupId={props.groupId}
                  />
                </DimensionGroupContainer>
              </WarningComponent>
            </Box>
          </Tooltip>

          {props.composition.materials.map((x) => {
            return (
              <CompositionMaterialView
                key={x.id}
                onClick={() => editOnOpen(x)}
                onRemoved={() => compositionEvents.onMaterialRemoved.dispatch()}
                material={x}
                settings={{ colors: props.isMatrix ? 'hidden' : undefined }}
              />
            );
          })}

          <WarningComponent
            warning={anyMaterialMissing(props.composition, t)}
            w="auto"
          >
            <AddMaterial
              formType={props.isMatrix ? 'controller' : undefined}
              compositionId={props.composition.id}
              productGroupId={props.composition.articleProductGroupId}
              onCreated={() => compositionEvents.onMaterialAdded.dispatch()}
            />
          </WarningComponent>
        </Flex>
      </Box>

      {value && (
        <UpdateCompositionMaterial
          formType={props.isMatrix ? 'controller' : undefined}
          onSubmit={onSubmit}
          compositionMaterialId={value.id}
          productGroupId={props.composition.articleProductGroupId}
          isOpen={editIsOpen}
          onClose={editOnClose}
        />
      )}
      <VerifyDialogWithRequest
        headerTitle={t('composition.archiveComposition')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        isOpen={isArchiveOpen}
        onClose={onArchiveClose}
        onPerformed={() => compositionEvents.onArchived.dispatch()}
        primaryRequest={compositionApi.archiveComposition}
        args={[props.composition.id]}
        onSuccessTitle={t('composition.archived')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
      <RenameCompositionModal
        composition={props.composition}
        onUpdated={() => compositionEvents.onRename.dispatch()}
        isOpen={isRenameOpen}
        onClose={onRenameClose}
      />
    </Box>
  );
}

function missingDimensionGroup(composition: Composition, t: TFunction) {
  return composition.dimension === null
    ? t('composition.missingDimensionGroup')
    : undefined;
}

function anyMaterialMissing(composition: Composition, t: TFunction) {
  return composition.materials.length === 0
    ? t('compositionGroup.option.missingMaterials')
    : undefined;
}
