import {
  Flex,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tag,
  useDisclosure,
  useToast,
  Button,
  Heading,
} from '@chakra-ui/react';
import {
  Composition,
  compositionApi,
  CompositionData,
  CompositionMaterial,
  UpdateCompositionMaterialRequest,
} from '@texas/api/endpoints/compositionApi';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { ColorDot } from '../../../../../shared/colorPicker/ColorDot';
import { ColorBox } from '../../../../../shared/colorPicker/ColorBox';
import { Icons } from '@texas/components/shared/Icons';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { clientEndpoints } from '@texas/clientEndpoints';
import { ImageDropzone } from '@texas/components/shared/dropzone/ImageDropzone';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useCallback, useEffect } from 'react';
import { FileLink, filesApi } from '@texas/api/endpoints/filesApi';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ServerError } from '@texas/types';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import {
  acceptedImageFormats,
  imageExtensions,
} from '@texas/utils/helpers/filesHelper';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { FileBrowserModal } from '@texas/components/shared/files/FileBrowserModal';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { maxSize } from '@texas/components/shared/dropzone/shared';
import { FileDropzone } from '@texas/components/shared/dropzone/FileDropzone';
import { Character } from '@texas/components/shared/composition/character/character';
import { GroupContext } from '../group/CompositionGroupView';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { GetColorWithType } from '@texas/components/shared/colorPicker/ColorPicker';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { CharacterContainer } from '@texas/components/shared/composition/character/CharacterContainer';
import { DimensionGroupContainer } from '@texas/components/shared/composition/dimension/DimensionGroupContainer';
import { AddMaterial } from '@texas/components/shared/composition/material/AddMaterial';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { UpdateCompositionMaterial } from '@texas/components/shared/composition/material/UpdateCompositionMaterial';
import { RemoveComponent } from '@texas/components/shared/RemoveComponent';
import {
  MetadataList,
  MetadataListRow,
} from '@texas/components/shared/MetadataList';
import { metricShortName } from '@texas/components/shared/composition/dimension/translations';
import { DimensionValuesContainer } from '@texas/components/shared/composition/dimension/DimensionValuesContainer';

export type MaterialMode = 'read-only' | 'only-edit';

export function DetailedCompositon({
  composition,
  archiveDisabled,
}: {
  composition: Composition;
  archiveDisabled: boolean;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { group, componentSettings, anyProductApproval } =
    useActiveContext(GroupContext);

  const {
    isOpen: isFileBrowserOpen,
    onClose: onFileBrowserClose,
    onOpen: onFileBrowserOpen,
  } = useDisclosure();

  const {
    isOpen: isRemoveFileOpen,
    onClose: onRemoveFileClose,
    onOpen: onRemoveFileOpen,
  } = useDisclosure();

  const {
    isOpen: editIsOpen,
    value,
    onClose: editOnClose,
    onOpen: editOnOpen,
  } = useValueDisclosure<CompositionMaterial>();

  const {
    data: file,
    set: setFile,
    loading: loadingFile,
    refetch,
    error,
  } = useApiResource(filesApi.getFileLink);

  useEffect(() => {
    if (composition.fileId) refetch(composition.fileId);
    else setFile(null);
  }, [composition.fileId, refetch, setFile]);

  const { request: updateRequest } = useApiRequest(compositionApi.updateFile);

  const updateFile = useCallback(
    (file: FileLink) => {
      request(
        updateRequest,
        [composition.id, file.id],
        (_) => {
          toast({
            title: t('fileBrowser.updatedFile'),
            status: 'success',
            isClosable: true,
          });
          setFile(file);
          compositionEvents.fileUpdated.dispatch();
        },
        (error: ServerError) => {
          toast({
            title: t('fileBrowser.updateFileFailed'),
            description: <ErrorsList errors={error.errors} />,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [composition.id, setFile, t, toast, updateRequest],
  );

  const { request: updateMaterialRequest } = useApiRequest(
    compositionApi.updateCompositionMaterial,
  );
  const onSubmit = async (data: UpdateCompositionMaterialRequest) =>
    await request(
      updateMaterialRequest,
      [value!.id, data],
      (_) => {
        compositionEvents.onMaterialChanged.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateFile, maxSize);

  return (
    <Flex flexDir="column" gap={2} animation={fadeInLeftAnimation()}>
      {composition.archived && !archiveDisabled && (
        <Flex justify="center" gap={2} mt={2}>
          <Tag
            color="texas.sand.100"
            _light={{ color: 'texas.burntSienna.500' }}
          >
            <Icons.AlertCircle mr={1} />
            {`${t('general.archived')} ${formatDate(
              Locale.En,
              composition.archived,
            )}`}
          </Tag>
        </Flex>
      )}
      <Heading pl={2} pt={2} fontSize="md">
        {t('fileBrowser.image')}
      </Heading>
      {error && <ErrorDetails error={error} />}
      {file && (
        <ImageDropzone
          imageId={file.id}
          imageIdentifier={file.identifier}
          imageSrc={clientEndpoints.previewImage(file.identifier, 312)}
          imageArchived={file.archived}
          allowEdit={componentSettings?.editImage === 'hidden' ? false : true}
          onUpload={(files) => uploadFiles(files, composition.folderId)}
          isUploading={isUploading}
          uploadFailed={uploadFailed}
          fileUploads={fileUploads}
          abortFileUpload={abortFileUpload}
          onRemovefileOpen={onRemoveFileOpen}
          onFileBrowserOpen={onFileBrowserOpen}
          webcamPhotoPrefix="composition"
        />
      )}
      {componentSettings?.editImage === 'hidden' && !loadingFile && !file && (
        <Text pl={4} variant="light">
          {t('fileBrowser.noImage')}
        </Text>
      )}
      {componentSettings?.editImage !== 'hidden' &&
        !loadingFile &&
        !file &&
        !composition.archived &&
        !group?.archived && (
          <Box m={2}>
            <FileDropzone
              accept={acceptedImageFormats}
              isUploading={isUploading}
              uploadFailed={uploadFailed}
              fileUploads={fileUploads}
              abortFileUpload={abortFileUpload}
              onUpload={(files) => uploadFiles(files, composition.folderId)}
              onFileBrowserOpen={onFileBrowserOpen}
              webcamPhotoPrefix="composition"
            />
          </Box>
        )}
      {composition.note && <Text textAlign="center">{composition.note}</Text>}
      <Flex gap={4} flexDir="column" p={2} pt={6}>
        <CharacterContainer
          mode={
            componentSettings?.characterEdit === 'hidden'
              ? 'read-only'
              : undefined
          }
          character={convertToEnum(Character, composition.character)}
          compositionId={composition.id}
          onChange={() => compositionEvents.onCharacterSet.dispatch()}
        />
        <DimensionGroupContainer
          mode={componentSettings?.dimensionMode}
          composition={composition}
          onGroupChange={() => compositionEvents.onDimensionGroupSet.dispatch()}
        >
          <Heading pb={2} fontSize="md">
            {t('configuration.dimensions')}
          </Heading>
          {anyProductApproval ? (
            <>
              <MetadataList>
                {composition.dimension?.dimensions.map((d) => {
                  return (
                    <MetadataListRow key={d.id} label={d.label}>
                      {d.value} {metricShortName(d.metric)}
                    </MetadataListRow>
                  );
                })}
              </MetadataList>
            </>
          ) : (
            <>
              <DimensionValuesContainer
                groupId={group!.id}
                composition={composition}
              />
            </>
          )}
        </DimensionGroupContainer>
      </Flex>
      <Accordion
        allowMultiple={true}
        defaultIndex={composition.materials.map((x, i) => i)}
      >
        {composition.materials.map((x) => (
          <MaterialComposition
            mode={
              anyProductApproval
                ? 'read-only'
                : componentSettings?.materialsMode
            }
            key={x.id}
            compositionMaterial={x}
            onEdit={() => editOnOpen(x)}
            onRemoved={() => compositionEvents.onMaterialRemoved.dispatch()}
          />
        ))}
      </Accordion>
      {componentSettings?.addMaterials !== 'hidden' && (
        <Box p={2}>
          <AddMaterial
            compositionId={composition.id}
            productGroupId={composition.articleProductGroupId}
            onCreated={() => compositionEvents.onMaterialAdded.dispatch()}
          />
        </Box>
      )}
      <FileBrowserModal
        rootFolderId={composition.folderId}
        mode="Select"
        isOpen={isFileBrowserOpen}
        onClose={onFileBrowserClose}
        extensionFilter={imageExtensions}
        onSelect={(file: FileLink) => {
          updateFile(file);
          onFileBrowserClose();
        }}
      />
      <VerifyDialogWithRequest
        headerTitle={t('fileBrowser.removeImage')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionApi.deleteFile}
        args={[composition.id]}
        isOpen={isRemoveFileOpen}
        onClose={onRemoveFileClose}
        onPerformed={() => {
          setFile(null);
          compositionEvents.fileUpdated.dispatch();
        }}
        onSuccessTitle={t('fileBrowser.fileRemoved')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>

      {value && (
        <UpdateCompositionMaterial
          onSubmit={onSubmit}
          compositionMaterialId={value.id}
          productGroupId={composition.articleProductGroupId}
          isOpen={editIsOpen}
          onClose={editOnClose}
        />
      )}
    </Flex>
  );
}

function MaterialComposition({
  mode,
  compositionMaterial,
  onEdit,
  onRemoved,
}: {
  mode?: MaterialMode;
  compositionMaterial: CompositionMaterial;
  onEdit: () => void;
  onRemoved: () => void;
}) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { componentSettings } = useActiveContext(GroupContext);

  return (
    <RemoveComponent onClick={mode === undefined ? onOpen : undefined}>
      <AccordionItem>
        <AccordionButton
          gap={2}
          bg="whiteAlpha.50"
          textAlign="start"
          _hover={{ bg: 'whiteAlpha.100' }}
          _light={{ bg: 'blackAlpha.50', _hover: { bg: 'blackAlpha.100' } }}
        >
          <Flex flexDir="column">
            <Box
              onClick={(e) => {
                if (mode === 'read-only') return;

                onEdit();
                e.stopPropagation();
              }}
            >
              <Text fontWeight="bold">{compositionMaterial.material.text}</Text>
              {mode !== 'read-only' && (
                <Button
                  leftIcon={<Icons.Pencil />}
                  variant="link"
                  mt={2}
                  color="texas.sand.100"
                  onClick={(e) => {
                    onEdit();
                    e.stopPropagation();
                  }}
                >
                  {t('materials.edit')}
                </Button>
              )}
            </Box>
            <Text
              overflowWrap="anywhere"
              noOfLines={3}
              fontSize="sm"
              color="gray.300"
            >
              {compositionMaterial.note}
            </Text>
          </Flex>

          <Flex
            gap={2}
            ml="auto"
            minW="32px"
            flexWrap="wrap"
            justifyContent="end"
          >
            {compositionMaterial.colors.map((x) => {
              const color = x.colorType == null ? GetColorWithType(x) : x;

              return (
                <ColorDot
                  key={color.name}
                  hex={color.hex}
                  name={color.name}
                  code={color.code}
                />
              );
            })}
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <CompositionDataView
            label={t('configuration.qualities')}
            data={compositionMaterial.qualities}
          />
          <CompositionDataView
            label={t('configuration.techniques')}
            data={compositionMaterial.techniques}
          />
          <CompositionDataView
            label={t('configuration.treatments')}
            data={compositionMaterial.treatments}
          />
          {componentSettings?.colors !== 'hidden' && (
            <>
              <Header label={t('general.colors')} />
              <Flex flexWrap="wrap" gap={2}>
                {compositionMaterial.colors.length === 0 && <NoData />}
                {compositionMaterial.colors.map((x) => {
                  const color = x.colorType ? x : GetColorWithType(x);

                  return (
                    <ColorBox
                      key={color.code}
                      colorDescription={x.description}
                      color={color}
                    />
                  );
                })}
              </Flex>
            </>
          )}
        </AccordionPanel>
      </AccordionItem>

      <VerifyDialogWithRequest
        headerTitle={t('general.remove')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionApi.removeCompositionMaterial}
        args={[compositionMaterial.id]}
        isOpen={isOpen}
        onClose={onClose}
        onPerformed={onRemoved}
        onSuccessTitle={t('general.successfullyRemoved')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
    </RemoveComponent>
  );
}

function CompositionDataView({
  data,
  label,
}: {
  data: CompositionData[];
  label: string;
}) {
  return (
    <Box>
      <Header label={label} />
      {data.length === 0 && <NoData />}
      {data.map((x) => (
        <Flex fontSize="sm" key={x.id} alignItems="baseline">
          <Icons.CircleMedium pt="0.5" />
          <Text>{x.text}</Text>
          {x.value && <Text fontWeight="bold">: {x.value}</Text>}
        </Flex>
      ))}
    </Box>
  );
}

function Header({ label }: { label: string }) {
  return (
    <Text w="100%" variant="main" pt={1} pb="0.5">
      {label}
    </Text>
  );
}

function NoData({ label }: { label?: string }) {
  const { t } = useTranslation();

  return <Text variant="light">{label ? label : t('general.noData')}</Text>;
}
