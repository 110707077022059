import { isArray } from 'angular';
import { t } from 'i18next';
import { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { variantFilters } from '../../filter/variantFilters';
import { SwitchFilterElement } from '../../filter/SwitchFilter';
import { FilterOptionType, FilterTypeEnum } from '../../filter/types/types';
import {
  ArticleSearchProps,
  ArticleSearchView,
  FilterOptions,
} from '../../searchArticles/types';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useLocalStorage } from '@texas/hooks/useLocalStorage';
import { SelectFilterElement } from '../../filter/SelectFilter';
import { HttpClientResponse } from '@texas/api/httpClient/types';
import {
  FilterOptionIds,
  SearchVariantsBase,
  VariantsSearchQuery,
  VariantsSearchQueryRequest,
} from '@texas/api/endpoints/search/searchVariantsTypes';
import {
  VariantFilterResult,
  VariantsFilterType,
} from '@texas/components/filter/types/variantTypes';

export type ActiveFilterType = [manuallyAdded: boolean, type: FilterOptionType];

export function useSearchArticles<R extends SearchVariantsBase>({
  defaultFilters,
  defaultArticleSearchProps,
  overrideDefaultArticleSearchPage,
  defaultPage,
  limit,
  localStorageKey,
  filterOptions,
  optOutDefaultOrder = false,
  onFiltersChanged = undefined,
  request,
  defaultData,
}: {
  defaultFilters: FilterOptionIds;
  defaultArticleSearchProps: ArticleSearchProps;
  overrideDefaultArticleSearchPage?: ArticleSearchProps;
  defaultPage: number;
  limit: number;
  localStorageKey?: string;
  filterOptions?: FilterOptions;
  optOutDefaultOrder?: boolean;
  onFiltersChanged?: () => void;
  request: (
    query: VariantsSearchQueryRequest,
  ) => Promise<HttpClientResponse<R>>;
  defaultData: Omit<R, keyof SearchVariantsBase>;
}) {
  const [searchPage, setSearchPage] = useLocalStorage<ArticleSearchProps>(
    localStorageKey,
    defaultArticleSearchProps,
    overrideDefaultArticleSearchPage,
  );
  const searchParamsRef = useRef<VariantsSearchQuery>(searchPage.searchParams);
  const init = useRef(false);
  const defaultDataRef = useRef(defaultData);

  const filteredFilters = searchPage.filters
    .map((type) => variantFilters.find((f) => f.optionType === type))
    .filter((f) => f !== undefined) as VariantsFilterType[];

  const [activeFiltersVariantResults, setActiveFiltersVariantResults] =
    useState<VariantFilterResult[]>([
      {
        ...defaultFilters,
        isPopulated: false,
        optionType: undefined,
      },
      ...filteredFilters.map((f) => ({
        optionType: f.optionType,
        ...defaultFilters,
        isPopulated: false,
      })),
    ]);

  const [activeFilterTypes, setActiveFilterTypes] = useState<
    ActiveFilterType[]
  >(filteredFilters.map((f) => [false, f.optionType]));

  const setSearchParamsWithRef = useCallback(
    (params: VariantsSearchQuery) => {
      if (
        onFiltersChanged &&
        params.page === searchParamsRef.current.page &&
        params.sortBy === searchParamsRef.current.sortBy &&
        params.sortDesc === searchParamsRef.current.sortDesc
      ) {
        onFiltersChanged();
      }
      searchParamsRef.current = { ...params };
      setSearchPage((s) => ({
        ...s,
        searchParams: {
          ...params,
        },
      }));
    },
    [setSearchPage, onFiltersChanged],
  );
  const handleOnFilterRemove = useCallback(
    (filter: VariantsFilterType) => {
      setActiveFilterTypes((s) =>
        s.filter(([_, type]) => type !== filter.optionType),
      );
      setActiveFiltersVariantResults((s) =>
        s.filter((f) => f.optionType !== filter.optionType),
      );
      setSearchParamsWithRef({
        ...searchParamsRef.current,
        [filter.queryParamsKey]: undefined,
        page: defaultPage,
      });
      setSearchPage((s) => ({
        ...s,
        filters: s.filters.filter((f) => f !== filter.optionType),
      }));
    },
    [defaultPage, setSearchPage, setSearchParamsWithRef],
  );

  const handleViewChange = useCallback(
    (view: ArticleSearchView) => {
      if (searchPage.view === view) return;

      setSearchPage((s) => ({
        ...s,
        view: view,
      }));
    },
    [searchPage.view, setSearchPage],
  );

  const lookUpFilteredOptions = useCallback(
    (
      currentFilter: VariantsFilterType,
      previousActiveFilterIndex: number,
    ): number[] | undefined => {
      // ActiveFilterVariantResults is always +1 from activeFilters, since we add the search filter input as the first element in the array with optionType undefined
      let previousFilterResult =
        activeFiltersVariantResults[previousActiveFilterIndex];
      for (let i = previousActiveFilterIndex - 1; i >= 0; i--) {
        if (previousFilterResult.isPopulated) break;
        previousFilterResult = activeFiltersVariantResults[i];
      }

      if (
        !previousFilterResult.isPopulated ||
        currentFilter.type === FilterTypeEnum.Checkbox
      ) {
        return undefined;
      }
      return previousFilterResult[currentFilter.queryParamsKey];
    },
    [activeFiltersVariantResults],
  );

  const activeFiltersRef = useRef<VariantsFilterType[]>([]);
  const activeFilters = useMemo(
    () =>
      activeFilterTypes
        .map(([_, type]) => variantFilters.find((f) => f.optionType === type))
        .filter((f) => f !== undefined) as VariantsFilterType[],
    [activeFilterTypes],
  );
  useEffect(() => {
    activeFiltersRef.current = activeFilters;
  }, [activeFilters]);

  const {
    data: articles,
    refetch,
    set,
    loading,
    error,
  } = useApiResource(request);

  const fetchArticlesDebounce = useDebouncedCallback(() => {
    refetch({
      ...searchPage.searchParams,
      limit: limit,
      optOutDefaultOrdering: optOutDefaultOrder,
    });
  }, 300);

  useEffect(() => {
    if (!isSearchParamsEmpty(searchPage.searchParams)) {
      fetchArticlesDebounce();
    } else {
      set({
        ...(defaultDataRef.current as R),
        exportArticleItems: [],
        filterOptionIds: { ...defaultFilters },
        branchFilters: [],
        customerFilters: [],
        categoryCodeFilters: [],
        productGroupFilters: [],
        materialFilters: [],
        conceptFilters: [],
      });
    }
  }, [
    refetch,
    set,
    searchPage.searchParams,
    fetchArticlesDebounce,
    defaultFilters,
  ]);

  useEffect(() => {
    if (articles === null) return;

    setActiveFiltersVariantResults((r) => {
      let unpopulatedFilters = 0;
      for (let i = activeFiltersRef.current.length - 1; i >= 0; i--) {
        const value =
          searchParamsRef.current[activeFiltersRef.current[i].queryParamsKey];
        if (isArray(value) && value.length === 0) {
          unpopulatedFilters++;
          continue;
        }

        break;
      }
      if (!init.current) {
        init.current = true;
        return r.map((s) => {
          return { ...s, ...articles.filterOptionIds, isPopulated: true };
        });
      }

      return r.map((s, i) => {
        if (i === r.length - unpopulatedFilters - 1) {
          return { ...s, ...articles.filterOptionIds, isPopulated: true };
        }
        return s;
      });
    });
  }, [articles]);

  const filterElements = useMemo(() => {
    return activeFilters.map((f, i) => {
      const filterOption = filterOptions
        ? filterOptions[f.optionType]
        : undefined;

      if (filterOption?.disabled) {
        return null;
      }

      if (f.type === FilterTypeEnum.Select) {
        return (
          <SelectFilterElement
            static={filterOption ? filterOption.static : false}
            key={f.optionType}
            customOptionComponentView={f.customOptionComponentView}
            customValueLabelComponentView={f.customValueLabelComponentView}
            filteredOptions={lookUpFilteredOptions(f, i) ?? []}
            valueIds={searchPage.searchParams[f.queryParamsKey] ?? []}
            onChange={(ids) => {
              if (ids.length === 0) {
                setActiveFiltersVariantResults((s) =>
                  s.map((a) => {
                    if (a.optionType === f.optionType) {
                      return {
                        ...a,
                        ...defaultFilters,
                        isPopulated: false,
                      };
                    }
                    return a;
                  }),
                );
              }

              setSearchParamsWithRef({
                ...searchParamsRef.current,
                [f.queryParamsKey]: ids,
                page: defaultPage,
              });
            }}
            onRemove={() => handleOnFilterRemove(f)}
            minSearchTermLength={f.minSearchTermLength}
            fetchOptions={f.fetchOptions}
            fetchValues={f.fetchValues}
            name={f.getName(t)}
            defaultIsOpen={activeFilterTypes.some(
              ([manuallyAdded, type]) => type === f.optionType && manuallyAdded,
            )}
          />
        );
      } else {
        return (
          <SwitchFilterElement
            static={filterOption ? filterOption.static : false}
            key={f.optionType}
            showName={f.showName}
            onChange={(value: boolean) => {
              setSearchParamsWithRef({
                ...searchParamsRef.current,
                [f.queryParamsKey]: value,
                page: defaultPage,
              });
            }}
            onLabel={f.getOnLabel(t)}
            offLabel={f.getOffLabel(t)}
            checked={
              searchPage.searchParams[f.queryParamsKey] ?? f.defaultChecked
            }
            onRemove={() => handleOnFilterRemove(f)}
            name={f.getName(t)}
          />
        );
      }
    });
  }, [
    activeFilterTypes,
    activeFilters,
    defaultFilters,
    defaultPage,
    filterOptions,
    handleOnFilterRemove,
    lookUpFilteredOptions,
    searchPage.searchParams,
    setSearchParamsWithRef,
  ]);

  const availableFilters = variantFilters.filter((f) => {
    const alreadyAdded = activeFilterTypes.some(
      ([_, type]) => type === f.optionType,
    );
    const filterAvailable = filterOptions
      ? !(filterOptions[f.optionType]?.static ?? false)
      : true;
    return !alreadyAdded && filterAvailable;
  });

  return {
    articles,
    filterElements,
    loading,
    error,
    activeFilters,
    searchPage,
    searchParamsRef,
    setSearchPage,
    setSearchParamsWithRef,
    handleViewChange,
    setActiveFiltersVariantResults,
    setActiveFilterTypes,
    refetchArticles: fetchArticlesDebounce,
    availableFilters,
    view: searchPage.view ?? 'table',
  };
}

function isSearchParamsEmpty(searchParams: VariantsSearchQuery) {
  return (
    searchParams.searchTerm.length === 0 &&
    (searchParams.branchIds ?? []).length === 0 &&
    (searchParams.categoryCodeIds ?? []).length === 0 &&
    (searchParams.conceptIds ?? []).length === 0 &&
    (searchParams.customerIds ?? []).length === 0 &&
    (searchParams.states ?? []).length === 0 &&
    (searchParams.productGroupIds ?? []).length === 0 &&
    (searchParams.processes ?? []).length === 0 &&
    (searchParams.materialIds ?? []).length === 0 &&
    searchParams.showAllVariants === undefined &&
    searchParams.includeArchived === undefined &&
    searchParams.showMyBrands === undefined
  );
}
