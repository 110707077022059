import { TFunction } from 'i18next';
import { PagedItems } from '../../../types';
import { HttpClientResponse } from '@texas/api/httpClient/types';
import {
  FilterItem,
  SearchFilterQuery,
} from '@texas/api/endpoints/search/searchApi';

export interface BaseFilterProps {
  onRemove?: () => void;
  name: string;
  static: boolean;
}

export interface SelectFilterProps extends BaseFilterProps {
  valueIds: number[];
  onChange: (ids: number[]) => void;
  filteredOptions: number[];
  fetchValues: FetchValuesType;
  fetchOptions: FetchOptionsType;
  defaultIsOpen: boolean;
  minSearchTermLength: number;
  customOptionComponentView: ((value: FilterItem) => JSX.Element) | undefined;
  customValueLabelComponentView:
    | ((value: FilterItem) => JSX.Element)
    | undefined;
}

export interface SwitchFilterProps extends BaseFilterProps {
  onChange: (value: boolean) => void;
  offLabel: string;
  onLabel: string;
  checked: boolean;
  showName?: boolean;
}

export type FetchOptionsType = (
  query: SearchFilterQuery,
) => Promise<HttpClientResponse<PagedItems<FilterItem>>>;

export type FetchValuesType = (
  params: number[],
) => Promise<HttpClientResponse<FilterItem[]>>;

export enum FilterOptionType {
  Branches,
  Customers,
  Concepts,
  States,
  ShowAllOptions,
  CategoryCodes,
  IncludeArchived,
  Materials,
  ProductGroups,
  Processes,
  ShowMyBrands,
  Suppliers,
  PaStates,
}

export enum FilterTypeEnum {
  Checkbox,
  Select,
}

export interface BaseFilter {
  optionType: FilterOptionType;
  getName: (t: TFunction) => string;
}

export interface BaseSelectFilter extends BaseFilter {
  type: FilterTypeEnum.Select;
  fetchValues: FetchValuesType;
  fetchOptions: FetchOptionsType;
  minSearchTermLength: number;
  customOptionComponentView?: (value: FilterItem) => JSX.Element;
  customValueLabelComponentView?: (value: FilterItem) => JSX.Element;
}

export interface BaseCheckboxFilter extends BaseFilter {
  type: FilterTypeEnum.Checkbox;
  getOffLabel: (t: TFunction) => string;
  getOnLabel: (t: TFunction) => string;
  defaultChecked: boolean;
  showName?: boolean;
}
