import FinalizeError from '../dto/variant/finalizeErrorTypes';
import { httpClient } from '../httpClient/httpClient';
import { AutoUpdateRequest, AutoUpdateResponse } from './autoUpdateTypes';
import { FileLink } from './filesApi';
import { ArticleAxisInfo } from './articlesApi';
import { Character } from '@texas/components/shared/composition/character/character';
import { SimpleContact } from './contactsApi';

export enum ArticleProcess {
  KickOff = 1,
  SupplierEnquiry = 2,
  QuotationReview = 3,
  SalesOffer = 4,
  OfferApproved = 5,
  InitiateSampling = 6,
  SamplingUnderway = 7,
  SampleSentToBc = 8,
  SampleSentToBrand = 9,
  SampleApproved = 10,
}

export interface CreateProjectData {
  customerId: number;
  keyAccountManagerId: number;
  customerName: string;
  identifier: string;
  customerArchived: boolean;
}

export interface GarpData {
  itemCode: string;
  name: string;
  branchData: GarpBranchData[];
}

interface GarpBranchData {
  branchId: number;
  blockedFromSync: boolean;
  state: number;
  articleProcess: ArticleProcess;
  startDate: string;
  requestDate: string;
  endDate: string;
  requestCompletedate: string;
  sampleConfirmationDate: string;
  priceListsSyncedOn: string;

  toDoTaskId: number;
  categoryCodeId: number;
  categoryCode: string;
  customsCodeId: number;
  customsCode: string;
  countryOfOriginId: number;
  countryOfOrigin: string;
  productGroupId: number;
  productGroup: string;
  techniqueId: number;
  techniqueCode: string;
  materialId: number;
  materialCode: string;
  vatCode: string;
  tierPriceId: number;
  tierPrice: string;
  salesCurrency: string;
  salesExchangeRate?: number;
  purchaseCurrency: string;
  purchaseExchangeRate?: number;

  freightCost: number;
  handlingFee: number;
  supplier: string;
  totalSalesThisYear: number;
  titalSalesLastYear: number;

  season: string;
  unit: string;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;

  quantityPerUnit?: number;
  weight?: number;
  decimalsInQuantity?: number;
  decimalsInPrice?: number;
  purchasePoint?: number;
  purchaseQuantity?: number;
  leadTime?: number;

  syncedCategoryCode: string;
  syncedCustomsCode: string;
  syncedCountryOfOrigin: string;
  syncedProductGroupCode: string;
  syncedTechniqueCode: string;
  syncedMaterialCode: string;
  syncedTierPriceId?: number;
  syncedSalesCurrency: string;
  syncedSalesExchangeRate: number;
  syncedPurchaseCurrency: string;
  syncedPurchaseExchangeRate?: number;
  syncedFreightCost?: number;
  syncedHandlingFee?: number;
  syncedSupplier: string;
  syncedSeason: string;
  syncedDecimalsInQuantity?: number;
  syncedDecimalsInPrice?: number;
  syncedPurchasePoint?: number;
  syncedPurchaseQuantity?: number;
  syncedLeadTime?: number;
  syncedUnit: string;
  syncedQuantityPerUnit?: number;
  syncedWeight?: number;
  syncedCode1: string;
  syncedCode2: string;
  syncedCode3: string;
  syncedCode4: string;
  syncedCode5: string;
  syncedCode6: string;

  hasChanged: boolean;
}

export interface FinalizeRequest {
  branchIds?: number[];
}

export interface ArticleBranch {
  branchId: number;
  identifier: string;
  blockedFromSync: boolean;
  branchName: string;
  lastSync?: string;
}

export enum FieldValueType {
  Percentage = 1,
  Text = 2,
}

export interface ArticleBranchErpSyncChanges {
  branchId: number;
  modifications: ErpSyncedModifications[];
}

interface ErpSyncedModifications {
  label: string;
  modifiedFields: ModifiedSyncedErpField[];
}

interface ModifiedSyncedErpField {
  name: string;
  syncedValue: any;
  value: any;
  type: FieldValueType;
}

export interface SupplierVariant {
  name: string;
  id: number;
  branchId: number;
  contacts: SimpleContact[];
}

export interface Branch {
  name: string;
  id: number;
  identifier: string;
}

export interface VariantSuppliersOverview {
  suppliers: VariantBranchSupplier[];
  branches: Branch[];
}

export interface VariantBranchSupplier {
  id: number;
  variantId: number;
  supplierId: number;
  supplierName: string;
  branchId: number;
  disabled: boolean;
  mirrorSupplierId: number | null;
  mirroringSupplierIds: number[];
  contacts: string[];
}

export interface AutoUpdateInfoRequest {
  name: string;
  character: Character;
  notes: string;
}

export interface VariantInfo {
  id: number;
  name: string;
  character: Character;
  notes: string;
  productGroupId?: number;
}

export interface VariantFilesInfo {
  id: number;
  folderId: number;
  artworkImageId?: number;
  artworkImageIdentifier?: string;
  artworkImageArchived: boolean;
  artworkDocumentId?: number;
  artworkDocument: FileLink | null;
  photoId?: number;
  photoIdentifier?: string;
  photoArchived: boolean;
}

export interface AddSupplierBranchRequest {
  supplierId: number;
  branchId: number;
  contactIds: number[];
}
export interface UpdateSupplierContactsRequest {
  contactIds: number[];
}

export interface SupplierContact {
  name: string;
  email: string;
  id: number;
}

export const variantsApi = {
  validateVariant: (variantId: number) =>
    httpClient.get<FinalizeError[]>(`variants/${variantId}/validate`),
  finalize: (variantId: number, request: FinalizeRequest) =>
    httpClient.post<GarpData>(`variants/${variantId}/finalize`, {
      data: request,
    }),
  getArticleBranches: (variantId: number) =>
    httpClient.get<ArticleBranch[]>(`variants/${variantId}/branches`),
  getSyncChanges: (variantId: number) =>
    httpClient.get<ArticleBranchErpSyncChanges[]>(
      `variants/${variantId}/changes`,
    ),
  getSuppliers: (variantId: number) =>
    httpClient.get<SupplierVariant[]>(`variants/${variantId}/suppliers`),
  getVariantBranchSuppliers: (variantId: number, includeDisabled: boolean) =>
    httpClient.get<VariantSuppliersOverview>(
      `variants/${variantId}/suppliers/overview`,
      { params: { includeDisabled } },
    ),
  addSupplierBranch: (variantId: number, request: AddSupplierBranchRequest) =>
    httpClient.post<VariantBranchSupplier>(`variants/${variantId}/suppliers`, {
      data: request,
    }),
  updateSupplierContacts: (
    variantSupplierId: number,
    request: UpdateSupplierContactsRequest,
  ) =>
    httpClient.put(`variants/suppliers/${variantSupplierId}/contacts`, {
      data: request,
    }),
  getSupplierContacts: (variantSupplierId: number) =>
    httpClient.get<SupplierContact[]>(
      `variants/suppliers/${variantSupplierId}/contacts`,
    ),
  disableSupplierBranch: (variantSupplierId: number) =>
    httpClient.put(`variants/suppliers/${variantSupplierId}/disable`),
  enableSupplierBranch: (variantSupplierId: number) =>
    httpClient.put(`variants/suppliers/${variantSupplierId}/enable`),
  mirrorSupplier: (variantSupplierId: number, toMirrorId: number) =>
    httpClient.put(
      `variants/suppliers/${variantSupplierId}/mirror/${toMirrorId}`,
    ),
  removeMirror: (variantSupplierId: number) =>
    httpClient.delete(`variants/suppliers/${variantSupplierId}/mirror`),
  getVariantInfo: (variantId: number) =>
    httpClient.get<VariantInfo>(`variants/${variantId}/info`),
  autoUpdateInfo: (
    variantId: number,
    request: AutoUpdateRequest<AutoUpdateInfoRequest>,
  ) =>
    httpClient.put<AutoUpdateResponse<VariantInfo>>(
      `variants/auto/${variantId}/info`,
      { data: request },
    ),
  getVariantFilesInfo: (variantId: number) =>
    httpClient.get<VariantFilesInfo>(`variants/${variantId}/files-info`),
  updateArtworkDocument: (variantId: number, documentId: number) =>
    httpClient.put(`variants/${variantId}/artworkdocument/${documentId}`),
  deleteArtworkDocument: (variantId: number) =>
    httpClient.delete(`variants/${variantId}/artworkdocument`),
  updateArtworkImage: (variantId: number, imageId: number) =>
    httpClient.put(`variants/${variantId}/artworkimage/${imageId}`),
  deleteArtworkImage: (variantId: number) =>
    httpClient.delete(`variants/${variantId}/artworkimage`),
  updatePhoto: (
    variantId: number,
    photoId: number,
    matrixNodeValueXId?: number,
    matrixNodeValueYId?: number,
  ) =>
    httpClient.put(`variants/${variantId}/photo/${photoId}`, {
      params: { matrixNodeValueXId, matrixNodeValueYId },
    }),
  deletePhoto: (
    variantId: number,
    matrixNodeValueXId?: number,
    matrixNodeValueYId?: number,
  ) =>
    httpClient.delete(`variants/${variantId}/photo`, {
      params: { matrixNodeValueXId, matrixNodeValueYId },
    }),
  getAxisInfo: (id: number) =>
    httpClient.get<ArticleAxisInfo>(`variants/${id}/matrix/axis`),
  downloadExcel: (variants: { variantId: number; branchId: number }[]) =>
    httpClient.post<any>(`excel/variants/export`, {
      data: variants,
      responseType: 'blob',
    }),
};
