import { httpClient } from '@texas/api/httpClient/httpClient';

export enum ColorType {
  PantoneTpg = 1,
  PantoneTcx = 2,
  RudholmMatrix = 3,
  Custom = 4,
  MetalShade = 5,
}

export interface Color {
  id: number;
  name: string;
  code: string;
  description: string | null;
  hex: string | null;
  erpText: string | null;
  colorType: ColorType;
}

export interface ColorSearch {
  colorType?: ColorType;
}

export const ColorsApi = {
  getAll: (params: ColorSearch) =>
    httpClient.get<Color[]>(`metadata/colors/all`, {
      params,
    }),
};
