import { TextProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type GroupType = 'version' | 'option';

interface Props extends TextProps {
  version: number;
  option?: number | null;
  note: string | null;
  type: GroupType;
}

export function CompositionGroupTitle({
  type,
  version,
  option,
  note,
  ...props
}: Props) {
  const { t } = useTranslation();

  if (type === 'option') {
    return (
      <>
        {note ? (
          <Text variant="main" {...props}>
            {t('compositionGroup.option.optionNrNote', { note, nr: version })}
          </Text>
        ) : (
          <Text variant="main" {...props}>
            {t('compositionGroup.option.optionNr', { nr: version })}
          </Text>
        )}
      </>
    );
  }

  return (
    <>
      {option ? (
        note ? (
          <Text variant="main" {...props}>
            {t('compositionGroup.version.versionNrNote', {
              nr: version,
              note,
              optionNr: option,
            })}
          </Text>
        ) : (
          <Text variant="main" {...props}>
            {t('compositionGroup.version.versionNrOption', {
              nr: version,
              optionNr: option,
            })}
          </Text>
        )
      ) : (
        <Text variant="main" {...props}>
          {t('compositionGroup.version.versionNr', {
            nr: version,
          })}
        </Text>
      )}
    </>
  );
}
