import {
  Button,
  Flex,
  Heading,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { VariantBranchSupplier } from '@texas/api/endpoints/variantsApi';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { useTranslation } from 'react-i18next';

export interface MirrorProps {
  type: 'main' | 'follower';
  value: string;
}

export function SupplierCell({
  supplier,
  mirrorValue,
  onDisable,
  onEnable,
  onMirrorClick,
  onChooseContacts,
  mirrorModeOnClick,
}: {
  supplier: VariantBranchSupplier;
  mirrorValue: MirrorProps | null;
  onDisable: (supplierId: number, branchId: number) => void;
  onEnable: (supplierId: number, branchId: number) => void;
  onMirrorClick: () => void;
  onChooseContacts: (supplierId: number) => void;
  mirrorModeOnClick?: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex
      direction="column"
      grow={1}
      py={2}
      px={4}
      align="start"
      onClick={mirrorModeOnClick}
    >
      <Heading
        fontSize="md"
        variant={supplier.disabled ? 'sub' : undefined}
        textDecoration={supplier.disabled ? 'line-through' : undefined}
      >
        {supplier.supplierName}
      </Heading>
      {supplier.contacts.length > 0 && (
        <Tooltip label={supplier.contacts.join(', ')}>
          <Text fontSize="sm" variant="sub">
            {t('variant.supplier.contactsConfigured', {
              count: supplier.contacts.length,
            })}
          </Text>
        </Tooltip>
      )}
      {mirrorValue && (
        <Tag
          mt={2}
          color="white"
          bg={mirrorValue.type === 'main' ? 'texas.sand.100' : 'gray.500'}
          _light={{
            bg:
              mirrorValue.type === 'main'
                ? 'texas.burntSienna.100'
                : 'gray.200',
          }}
        >
          <TagLeftIcon boxSize={defaultIconSize} as={Icons.Link} />
          <TagLabel fontWeight="bold">{mirrorValue.value}</TagLabel>
        </Tag>
      )}
      {mirrorModeOnClick === undefined && (
        <>
          <Flex gap={2}>
            {supplier.disabled ? (
              <Button
                p={0}
                size="xs"
                variant="texas-link"
                color="whiteAlpha.600"
                _light={{ color: 'blackAlpha.800' }}
                textDecoration="underline"
                visibility="hidden"
                _groupHover={{ visibility: 'visible' }}
                onClick={() => onEnable(supplier.supplierId, supplier.branchId)}
              >
                {t('general.enable')}
              </Button>
            ) : (
              <>
                {mirrorValue?.type !== 'main' && (
                  <Button
                    p={0}
                    size="xs"
                    variant="texas-link-danger"
                    visibility="hidden"
                    _groupHover={{ visibility: 'visible' }}
                    onClick={() =>
                      onDisable(supplier.supplierId, supplier.branchId)
                    }
                  >
                    {t('general.disable')}
                  </Button>
                )}

                <Button
                  p={0}
                  size="xs"
                  variant="texas-link"
                  visibility="hidden"
                  _groupHover={{ visibility: 'visible' }}
                  onClick={() => onChooseContacts(supplier.supplierId)}
                >
                  {t('variant.supplier.changeContacts')}
                </Button>
                {mirrorValue?.type !== 'main' && (
                  <Button
                    p={0}
                    size="xs"
                    variant="texas-link"
                    visibility="hidden"
                    _groupHover={{ visibility: 'visible' }}
                    onClick={onMirrorClick}
                  >
                    {mirrorValue?.type === 'follower'
                      ? 'Stop mirror'
                      : t('general.mirror')}
                  </Button>
                )}
              </>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
}
