import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { AngularNavLink } from '../shared/AngularNavLink';
import { ProductApprovalItem } from '@texas/api/endpoints/search/searchPaTypes';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { ProductApprovalHistoryModal } from '../article/productApproval/ProductApprovalHistoryModal';
import { SelectState } from '../article/productApproval/SelectState';
import { productApprovalEvents } from '../article/productApproval/events';

export const PaTableColumns = (t: TFunction) => {
  const columnHelper = createColumnHelper<ProductApprovalItem>();
  const columns = [
    columnHelper.accessor('formatNumber', {
      cell: (info) => (
        <AngularNavLink
          to="article.productApproval"
          navobj={{
            articleId: info.row.original.articleId,
            searchTerm: info.getValue(),
            state: info.row.original.state,
          }}
        >
          {info.getValue()}
        </AngularNavLink>
      ),
      header: t('productApproval.number'),
    }),
    columnHelper.accessor('itemCode', {
      cell: (info) => (
        <AngularNavLink
          to="article"
          navobj={{
            articleId: info.row.original.articleId,
          }}
        >
          {info.getValue()}
        </AngularNavLink>
      ),
      header: t('general.itemCode'),
    }),
    columnHelper.accessor('variantName', {
      cell: (info) => (
        <AngularNavLink
          to="variant"
          navobj={{
            articleId: info.row.original.articleId,
            variantId: info.row.original.variantId,
          }}
        >
          <Flex direction="column">
            <Box>{info.getValue()}</Box>
            {info.row.original.matrixNodeXValue &&
              info.row.original.matrixNodeYValue && (
                <>
                  <Text variant="sub">
                    {info.row.original.matrixNodeXValue}
                  </Text>
                  <Text variant="sub">
                    {info.row.original.matrixNodeYValue}
                  </Text>
                </>
              )}
          </Flex>
        </AngularNavLink>
      ),
      header: t('variant.variant'),
    }),
    columnHelper.accessor('customerName', {
      cell: (info) => info.getValue(),
      header: t('general.brand'),
      enableSorting: false,
    }),
    columnHelper.accessor('concept', {
      cell: (info) => info.getValue(),
      header: t('general.concept'),
      enableSorting: false,
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.description'),
      enableSorting: false,
    }),
    columnHelper.accessor('branch', {
      cell: (info) => info.getValue(),
      header: t('general.branch'),
      enableSorting: false,
    }),
    columnHelper.accessor('supplierName', {
      cell: (info) => info.getValue(),
      header: t('suppliers.supplier'),
      enableSorting: false,
    }),
    columnHelper.accessor('created', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.created'),
    }),
    columnHelper.accessor('state', {
      cell: (info) => info.getValue(),
      header: t('general.state'),
      enableSorting: false,
    }),
    columnHelper.accessor('productApprovalId', {
      cell: (info) => (
        <Flex gap={1}>
          <SelectState
            onChanged={() =>
              productApprovalEvents.productApprovalChanged.dispatch({
                id: info.row.original.productApprovalId,
              })
            }
            state={convertToEnum(ProductApprovalState, info.row.original.state)}
            id={info.row.original.productApprovalId}
            branchId={info.row.original.branchId}
            variantId={info.row.original.variantId}
          />
          <ProductApprovalHistoryModal
            productApprovalId={info.row.original.productApprovalId}
            number={info.row.original.formatNumber}
          />
        </Flex>
      ),
      header: '',
    }),
  ];

  return columns;
};
