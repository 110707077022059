import {
  Flex,
  Box,
  Text,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { Color, ColorWithType } from './ColorPicker';
import { RemoveComponent } from '../RemoveComponent';
import checker from '@assets/checker.svg';
import { defaultIconSize, Icons } from '../Icons';
import { EditableControls } from '../EditableControls';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compareEnum } from '@texas/utils/helpers/enumHelpers';
import { ColorType } from '@texas/api/endpoints/metadata/colorsApi';

export function ColorBox({
  color,
  colorDescription,
  colorName,
  onRemove,
  onEditDesc,
  onEditName,
  onColorChange,
  fallbackDefaultValueHex,
}: {
  color: Color | ColorWithType;
  colorDescription?: string;
  colorName?: string;
  onRemove?: () => void;
  onEditDesc?: (description: string) => void;
  onEditName?: (name: string) => void;
  onColorChange?: (hex: string) => void;
  fallbackDefaultValueHex?: string;
}) {
  const [hex, setHex] = useState(color.hex);
  const { t } = useTranslation();
  const hideColorVisualization =
    'colorType' in color
      ? compareEnum(ColorType, color.colorType, ColorType.MetalShade)
      : false;

  useEffect(() => {
    setHex(color.hex);
  }, [color.hex]);

  return (
    <RemoveComponent onClick={onRemove}>
      <Flex
        direction="column"
        animation={fadeInScaleAnimation()}
        borderRadius="md"
        overflow="hidden"
        border="1px solid gray"
        minH={hideColorVisualization ? 'fit-content' : '60px'}
      >
        {!hideColorVisualization &&
          ((hex?.length ?? 0) > 0 ? (
            <Box h={8} bgColor={`#${hex}`}>
              {onColorChange && (
                <ChangeColor
                  onBlur={onColorChange}
                  onChange={(h) => setHex(h)}
                  defaultValue={`#${hex ?? fallbackDefaultValueHex}`}
                />
              )}
            </Box>
          ) : (
            <Box h={8} bg={`url(${checker})`} backgroundSize="contain">
              {onColorChange && (
                <ChangeColor
                  onBlur={onColorChange}
                  onChange={(h) => setHex(h)}
                  defaultValue={`#${fallbackDefaultValueHex}`}
                />
              )}
            </Box>
          ))}
        <Flex
          bg="black"
          _light={{ bg: 'white' }}
          px={2}
          py={1}
          flexDir="column"
          color="white"
          flexGrow={1}
        >
          <Flex gap={2} alignItems="baseline">
            <Text variant="main">{color.code}</Text>
            {onEditName ? (
              <>
                <Editable as={Flex} gap={1} defaultValue={colorName}>
                  <EditablePreview
                    color="gray.200"
                    _light={{ color: 'gray.400' }}
                  />
                  <EditableInput
                    maxLength={30}
                    onChange={(e) => onEditName(e.target.value)}
                    _light={{ color: 'black' }}
                  />
                  <EditableControls />
                </Editable>
              </>
            ) : (
              <Text variant="sub">{color.name}</Text>
            )}
          </Flex>
          {onEditDesc ? (
            <>
              <Editable
                as={Flex}
                gap={1}
                placeholder={t('colors.addDescription')}
                defaultValue={colorDescription}
              >
                <EditablePreview color="gray.400" />
                <EditableInput
                  maxLength={30}
                  onChange={(e) => onEditDesc(e.target.value)}
                  _light={{ color: 'black' }}
                />
                <EditableControls />
              </Editable>
            </>
          ) : (
            <Text variant="small">{colorDescription}</Text>
          )}
        </Flex>
      </Flex>
    </RemoveComponent>
  );
}

function ChangeColor({
  onChange,
  onBlur,
  defaultValue,
}: {
  onChange: (hex: string) => void;
  onBlur: (hex: string) => void;
  defaultValue?: string;
}) {
  const { t } = useTranslation();
  return (
    <Tooltip label={t('colors.changeColor')}>
      <FormLabel m={0} boxSize="full" pos="relative" cursor="pointer">
        <Flex h="full" justify="center" align="center">
          <Box
            p={1}
            borderRadius="full"
            bg="whiteAlpha.800"
            _groupHover={{ opacity: 1, transform: 'scale(1)' }}
            opacity={0}
            transform="scale(0.3)"
            transition="transform 200ms ease, opacity 200ms ease"
          >
            <Icons.Pencil color="black" boxSize={defaultIconSize} />
          </Box>
        </Flex>
        <Input
          defaultValue={defaultValue}
          pos="absolute"
          bottom={1}
          right={1}
          boxSize={0}
          margin={0}
          border={0}
          padding={0}
          onBlur={(e) => {
            onBlur(e.target.value.slice(1, e.target.value.length));
          }}
          onChange={(e) =>
            onChange(e.target.value.slice(1, e.target.value.length))
          }
          type="color"
        />
      </FormLabel>
    </Tooltip>
  );
}
