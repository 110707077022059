import { Box, Text } from '@chakra-ui/react';
import { Color, ColorWithType } from './ColorPicker';
import checker from '@assets/checker.svg';
import { compareEnum } from '@texas/utils/helpers/enumHelpers';
import { ColorType } from '@texas/api/endpoints/metadata/colorsApi';

export function ColorComponent({
  color,
  selected,
  onSelect,
}: {
  color: Color | ColorWithType;
  selected: boolean;
  onSelect: () => void;
}) {
  const hideColorVisualization =
    'colorType' in color
      ? compareEnum(ColorType, color.colorType, ColorType.MetalShade)
      : false;

  return (
    <Box
      borderRadius="md"
      margin={1}
      overflow="hidden"
      cursor="pointer"
      onClick={onSelect}
      borderColor="transparent"
      borderStyle="solid"
      borderWidth="2px"
      transition="transform 200ms ease, border-color 200ms ease"
      _hover={{ transform: 'scale(1.02)' }}
      {...(selected
        ? { borderColor: 'white', _light: { borderColor: 'texas.bg.900' } }
        : null)}
    >
      {!hideColorVisualization && <ColorVisualization hex={color.hex} />}
      <Box
        bg="black"
        color="white"
        _light={{
          bg: hideColorVisualization ? 'white' : 'black',
          color: hideColorVisualization ? 'black' : 'white',
        }}
        p={2}
      >
        <Text>{color.code}</Text>
        <Text fontSize="sm">{color.name}</Text>
      </Box>
    </Box>
  );
}

function ColorVisualization({ hex }: { hex: string | null }) {
  return (
    <>
      {(hex?.length ?? 0) > 0 ? (
        <Box h="16" bgColor={`#${hex}`} />
      ) : (
        <Box
          h={16}
          bg={`url(${checker})`}
          backgroundSize="contain"
          opacity={0.2}
          backgroundColor="#b6bde5"
        />
      )}
    </>
  );
}
