import { SignalDispatcher } from 'ste-signals';
import { SimpleEventDispatcher } from 'ste-simple-events';

export interface DimensionValueChangedProps {
  groupId: number;
  compositionId: number;
  dimensionId: number;
  value: number;
}

export const compositionEvents = {
  onRename: new SignalDispatcher(),
  onAdd: new SignalDispatcher(),
  onUpdated: new SignalDispatcher(),
  onCharacterSet: new SignalDispatcher(),
  onMaterialAdded: new SignalDispatcher(),
  onMaterialRemoved: new SignalDispatcher(),
  onMaterialChanged: new SignalDispatcher(),
  onDimensionGroupSet: new SignalDispatcher(),
  onDimensionValueChanged:
    new SimpleEventDispatcher<DimensionValueChangedProps>(),
  onArchived: new SignalDispatcher(),
  onRestored: new SignalDispatcher(),
  fileUpdated: new SignalDispatcher(),
  onCopyDimensionValuesToAll: new SignalDispatcher(),
};
