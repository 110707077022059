import { SetStateAction, useEffect } from 'react';
import {
  compositionEvents,
  DimensionValueChangedProps,
} from '../composition/events';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';

type SharedType = CompositionGroup | CompositionGroup[];

export function useSetDimensionValueEvent<T = CompositionGroup>(
  set: (value: SetStateAction<T | null>) => void,
): void;
export function useSetDimensionValueEvent<T = CompositionGroup[]>(
  set: (value: SetStateAction<T | null>) => void,
): void;
export function useSetDimensionValueEvent(
  set: (value: SetStateAction<SharedType | null>) => void,
): void {
  useEffect(() => {
    return compositionEvents.onDimensionValueChanged.sub((data) => {
      set((x) =>
        x
          ? Array.isArray(x)
            ? mapGroups(x, data)
            : singleGroup(x, data)
          : null,
      );
    });
  }, [set]);
}

function singleGroup(x: CompositionGroup, data: DimensionValueChangedProps) {
  return {
    ...x,
    compositions: x.compositions.map((c) => {
      if (c.id === data.compositionId) {
        const dimensions = c.dimension!.dimensions.map((d) => {
          if (d.id === data.dimensionId) {
            return { ...d, value: data.value };
          }

          return d;
        });

        return { ...c, dimension: { ...c.dimension!, dimensions } };
      }

      return c;
    }),
  };
}

function mapGroups(x: CompositionGroup[], data: DimensionValueChangedProps) {
  return x.map((g) => {
    if (g.id === data.groupId) {
      return singleGroup(g, data);
    }

    return g;
  });
}
