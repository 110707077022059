import {
  Flex,
  TableContainer,
  Table,
  Thead,
  Text,
  Tr,
  Th,
  Tbody,
  Td,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Tooltip,
  Portal,
} from '@chakra-ui/react';
import {
  CompositionGroup,
  SimpleProductApproval,
} from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { CompositionGroupTitle } from '@texas/components/shared/compositionGroup/CompositionGroupTitle';
import { TexasIconSwitch } from '@texas/components/shared/form/TexasIconSwitch';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface CopyProps {
  groupId: number;
  supplierId: number;
  branchId: number;
  basedOnId: number | null;
  cellX: number | null;
  cellY: number | null;
  replaceId: number | null;
}

export function GroupedVersions({
  materialHeaders,
  header,
  versions,
  onRestoreOpen,
  onArchiveOpen,
  onOpen,
  onCopyOpen,
}: {
  materialHeaders: React.ReactNode;
  header: React.ReactNode;
  versions: CompositionGroup[];
  onRestoreOpen: (id: number) => void;
  onArchiveOpen: (id: number) => void;
  onOpen: (id: number) => void;
  onCopyOpen: (props: CopyProps) => void;
}) {
  const { t } = useTranslation();
  const hasArchivedVersions = versions.some((v) => v.archived);
  const [showArchived, setShowArchived] = useState<boolean>(false);

  const filteredVersions = useMemo(() => {
    if (showArchived) return versions;
    return versions.filter((v) => !v.archived);
  }, [showArchived, versions]);

  return (
    <Flex flexDir="column" pt={6}>
      <Flex gap={4}>
        {header}
        {hasArchivedVersions && (
          <TexasIconSwitch
            checked={showArchived}
            tooltip={t('compositionGroup.version.showArchived')}
            icon={<Icons.Archive boxSize={defaultIconSize} />}
            onChange={() => setShowArchived((s) => !s)}
          />
        )}
      </Flex>
      {filteredVersions.length === 0 ? (
        <Text variant="sub">{t('general.noData')}</Text>
      ) : (
        <TableContainer>
          <Table variant="versions-list-view">
            <Thead>
              <Tr>
                <Th w={6} />
                <Th w={6} />
                <Th>{t('suppliers.supplier')}</Th>
                <Th>{t('general.option')}</Th>
                <Th>{t('general.version')}</Th>
                {materialHeaders}
              </Tr>
            </Thead>
            <Tbody>
              {filteredVersions.map((v) => {
                return (
                  <Tr
                    key={v.id}
                    role="group"
                    onClick={() => onOpen(v.id)}
                    transition="opacity 200ms ease"
                    _hover={{ opacity: 0.7, cursor: 'pointer' }}
                  >
                    <Td>
                      <Flex gap={2} align="center">
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            size="sm"
                            icon={
                              <Icons.DotsHorizontal boxSize={defaultIconSize} />
                            }
                            aria-label="show more"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                          <Portal>
                            <MenuList>
                              {v.archived ? (
                                <>
                                  <MenuItem
                                    onClick={() => onRestoreOpen(v.id)}
                                    icon={
                                      <Icons.Undo boxSize={defaultIconSize} />
                                    }
                                  >
                                    {t(
                                      'compositionGroup.version.restoreVersion',
                                    )}
                                  </MenuItem>
                                </>
                              ) : (
                                <>
                                  {!v.productApprovals?.length ? (
                                    <>
                                      <MenuItem
                                        onClick={() => onOpen(v.id)}
                                        icon={
                                          <Icons.Pencil
                                            boxSize={defaultIconSize}
                                          />
                                        }
                                      >
                                        {t('compositionGroup.version.edit')}
                                      </MenuItem>
                                    </>
                                  ) : (
                                    <>
                                      <MenuItem
                                        onClick={() => onOpen(v.id)}
                                        icon={
                                          <Icons.OpenInNew
                                            boxSize={defaultIconSize}
                                          />
                                        }
                                      >
                                        {t('compositionGroup.version.view')}
                                      </MenuItem>
                                    </>
                                  )}

                                  <MenuItem
                                    onClick={() => onArchiveOpen(v.id)}
                                    icon={
                                      <Icons.Archive
                                        boxSize={defaultIconSize}
                                      />
                                    }
                                  >
                                    {t(
                                      'compositionGroup.version.archiveVersion',
                                    )}
                                  </MenuItem>
                                </>
                              )}

                              <MenuItem
                                onClick={() =>
                                  onCopyOpen({
                                    groupId: v.id,
                                    supplierId: v.supplierId!,
                                    branchId: v.branchId!,
                                    cellX: v.cellX,
                                    cellY: v.cellY,
                                    replaceId: null,
                                    basedOnId: v.basedOnGroupId,
                                  })
                                }
                                icon={
                                  <Icons.ContentCopy
                                    boxSize={defaultIconSize}
                                  />
                                }
                              >
                                {t('compositionGroup.version.copyIntoNew')}
                              </MenuItem>
                              {v.basedOnGroupId &&
                                !v.archived &&
                                !v.productApprovals?.length && (
                                  <MenuItem
                                    onClick={() =>
                                      onCopyOpen({
                                        groupId: v.basedOnGroupId!,
                                        supplierId: v.supplierId!,
                                        branchId: v.branchId!,
                                        cellX: v.cellX,
                                        cellY: v.cellY,
                                        replaceId: v.id,
                                        basedOnId: v.basedOnGroupId,
                                      })
                                    }
                                    icon={
                                      <Icons.Refresh
                                        boxSize={defaultIconSize}
                                      />
                                    }
                                  >
                                    {t(
                                      'compositionGroup.version.resetToOption',
                                    )}
                                  </MenuItem>
                                )}
                            </MenuList>
                          </Portal>
                        </Menu>
                        {v.archived && <Icons.Archive />}
                      </Flex>
                    </Td>
                    <Td>
                      <ProductApprovalData pas={v.productApprovals} />
                    </Td>
                    <Td>
                      <Text w="60" whiteSpace="normal">
                        {v.supplierName}
                      </Text>
                    </Td>
                    {v.basedOnGroupVersion ? (
                      <Td>
                        <CompositionGroupTitle
                          version={v.basedOnGroupVersion}
                          note={v.basedOnGroupName}
                          type="option"
                          variant="none"
                        />
                      </Td>
                    ) : (
                      <Td />
                    )}
                    <Td>
                      {t('compositionGroup.version.versionNr', {
                        nr: v.version,
                      })}
                    </Td>
                    {v.compositions.map((c) => {
                      return (
                        <React.Fragment key={c.id}>
                          {c.materials.map((m) => {
                            return (
                              <React.Fragment key={m.id}>
                                <Td>{m.material.text}</Td>
                                <Td>
                                  {m.qualities.map((q) => {
                                    return (
                                      <React.Fragment key={q.id}>
                                        <Text>{q.text}</Text>
                                      </React.Fragment>
                                    );
                                  })}
                                </Td>
                                <Td>
                                  {m.qualities.map((q) => {
                                    return (
                                      <React.Fragment key={q.id}>
                                        <Text>{q.value}</Text>
                                      </React.Fragment>
                                    );
                                  })}
                                </Td>

                                <Td>
                                  {m.treatments.map((t) => {
                                    return (
                                      <React.Fragment key={t.id}>
                                        <Text>{t.text}</Text>
                                      </React.Fragment>
                                    );
                                  })}
                                </Td>
                                <Td>
                                  {m.techniques.map((t) => {
                                    return (
                                      <React.Fragment key={t.id}>
                                        <Text>{t.text}</Text>
                                      </React.Fragment>
                                    );
                                  })}
                                </Td>
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  );
}

function ProductApprovalData({ pas }: { pas: SimpleProductApproval[] | null }) {
  const { t } = useTranslation();
  if (!pas || pas.length === 0) return null;

  const pendingPas = pas.filter(
    (p) =>
      ensureEnumNumber(ProductApprovalState, p.state) ==
      ProductApprovalState.Pending,
  ).length;
  const acceptedPas = pas.filter(
    (p) =>
      ensureEnumNumber(ProductApprovalState, p.state) ==
      ProductApprovalState.Accepted,
  ).length;
  const rejectedPas = pas.filter(
    (p) =>
      ensureEnumNumber(ProductApprovalState, p.state) ==
      ProductApprovalState.Rejected,
  ).length;

  if (pendingPas === 0 && acceptedPas === 0 && rejectedPas > 0) {
    return (
      <Tooltip label={t('compositionGroup.rejectedPa')}>
        <Icons.Cancel />
      </Tooltip>
    );
  }
  return (
    <Flex flexDir="column">
      {pendingPas > 0 && (
        <Tooltip label={t('compositionGroup.pendingPa')}>
          <Icons.Eye />
        </Tooltip>
      )}
      {acceptedPas > 0 && (
        <Tooltip label={t('compositionGroup.acceptedPa')}>
          <Icons.EyeCheck />
        </Tooltip>
      )}
    </Flex>
  );
}
