import { Box, Flex, useToast } from '@chakra-ui/react';

import { EmptyDataButton } from '@texas/components/shared/button/EmptyDataButton';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { CompositionComponent } from './CompositionComponent';
import { Icons } from '@texas/components/shared/Icons';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { compositionApi } from '@texas/api/endpoints/compositionApi';
import { request } from '@texas/utils/helpers/httpHelpers';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { useTranslation } from 'react-i18next';

export function OptionsComponent({
  option,
  isMatrix,
}: {
  option: CompositionGroup;
  isMatrix: boolean;
}) {
  return (
    <Flex flexGrow={1} gap={2} overflowX="auto" p={2}>
      {option.compositions.map((x, i) => {
        return (
          <CompositionComponent
            groupHasAnyBasedOn={option.hasAnyBasedOn}
            groupId={option.id}
            disableArchive={option.hasAnyBasedOn}
            isMatrix={isMatrix}
            key={x.id}
            index={i}
            composition={x}
            onMaterialChanged={() =>
              compositionEvents.onMaterialChanged.dispatch()
            }
          />
        );
      })}
      {!option.hasAnyBasedOn && (
        <Box py={32} px={8} textAlign="center">
          <NewComposition
            groupId={option.id}
            onAdd={() => compositionEvents.onAdd.dispatch()}
          />
        </Box>
      )}
    </Flex>
  );
}

function NewComposition({
  groupId,
  onAdd,
}: {
  groupId: number;
  onAdd: () => void;
}) {
  const { t } = useTranslation();
  const { request: addComposition, loading } = useApiRequest(
    compositionApi.addComposition,
  );
  const toast = useToast();

  const performRequest = async () =>
    await request(
      addComposition,
      [groupId],
      () => onAdd(),
      (error) => {
        toast({
          title: t('general.actionFailed'),
          status: 'error',
          description: error.message,
          isClosable: true,
        });
      },
    );

  return (
    <EmptyDataButton
      variant="large-center"
      icon={<Icons.LayersPlus boxSize={8} />}
      isLoading={loading}
      onClick={async () => {
        if (loading) return;
        await performRequest();
      }}
      label={t('composition.new')}
      description={t('composition.clickToAdd')}
    />
  );
}
