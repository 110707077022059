import { Flex, Tooltip, Text, Box } from '@chakra-ui/react';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { Icons } from '@texas/components/shared/Icons';
import { useContext } from 'react';
import { GroupContext } from './CompositionGroupView';
import { useTranslation } from 'react-i18next';
import { CompositionGroupTitle } from '@texas/components/shared/compositionGroup/CompositionGroupTitle';
import { AngularNavLink } from '@texas/components/shared/AngularNavLink';

export function CompositionGroupHeader({
  customTitle,
}: {
  customTitle?: string;
}) {
  const { group, productApprovalsCount, componentSettings } =
    useContext(GroupContext)!;
  const { t } = useTranslation();

  return (
    <Flex
      flexGrow={1}
      justifyContent="space-between"
      alignItems="center"
      p={2}
      borderRadius="md"
      opacity={group?.exists ? 1 : 0.6}
    >
      <Flex flexDir="column">
        <Text fontSize="sm" fontWeight="bold">
          {customTitle}
        </Text>
        {group?.exists ? (
          <>
            <CompositionGroupTitle
              version={group.version}
              option={group.supplierId ? group.basedOnGroupVersion : null}
              note={group.supplierId ? group.basedOnGroupName : group.note}
              type={group.supplierId ? 'version' : 'option'}
              fontSize="md"
            />
          </>
        ) : (
          <Text variant="sub">{t('general.noData')}</Text>
        )}
      </Flex>
      {componentSettings?.paStates !== 'hidden' && (
        <Flex gap={3}>
          {productApprovalsCount(ProductApprovalState.Pending) > 0 && (
            <Tooltip
              label={t('composition.pendingPa', {
                count: productApprovalsCount(ProductApprovalState.Pending),
              })}
            >
              <Box>
                <AngularNavLink
                  to="article.productApproval"
                  navobj={{
                    articleId: group?.articleId,
                    branchId: group?.branchId,
                    state: ProductApprovalState.Pending,
                    supplierId: group?.supplierId,
                  }}
                >
                  <Flex alignItems="center" gap={1}>
                    <Icons.Clock />
                    <Text fontWeight="bold">
                      {productApprovalsCount(ProductApprovalState.Pending)}
                    </Text>
                  </Flex>
                </AngularNavLink>
              </Box>
            </Tooltip>
          )}
          {productApprovalsCount(ProductApprovalState.Accepted) > 0 && (
            <Tooltip
              label={t('composition.acceptedPa', {
                count: productApprovalsCount(ProductApprovalState.Accepted),
              })}
            >
              <Box>
                <AngularNavLink
                  color="texas.sand.100"
                  to="article.productApproval"
                  navobj={{
                    articleId: group?.articleId,
                    branchId: group?.branchId,
                    state: ProductApprovalState.Accepted,
                    supplierId: group?.supplierId,
                  }}
                >
                  <Flex
                    _light={{ color: 'texas.burntSienna.500' }}
                    alignItems="center"
                    gap={1}
                  >
                    <Icons.CheckCircle />
                    <Text fontWeight="bold">
                      {productApprovalsCount(ProductApprovalState.Accepted)}
                    </Text>
                  </Flex>
                </AngularNavLink>
              </Box>
            </Tooltip>
          )}
        </Flex>
      )}
    </Flex>
  );
}
