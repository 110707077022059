import { reactEvents } from '../../../../bridge/reactEvents';

class ConceptsTabController {
  constructor(
    loadSpinnerService,
    ConceptsApiService,
    userSettings,
    $state,
    $scope,
    customer,
  ) {
    'ngInject';
    this._$state = $state;
    this.$scope = $scope;
    this.customer = customer;
    this._loadSpinnerService = loadSpinnerService;
    this._ConceptsApiService = ConceptsApiService;
    this.concepts = [];
    this.query = { order: 'name', limit: 50, page: 1 };
    this.userSettings = userSettings;
    this.loadConcepts();
    this.setGridType(userSettings.conceptsGridType);

    this.conceptEvent = reactEvents.conceptCreated.sub(() => {
      this.loadConcepts();
    });

    this.$scope.$on('$destroy', () => {
      this.conceptEvent();
    });
  }
  loadConcepts() {
    this._loadSpinnerService.start('mainSpinner');
    this._ConceptsApiService
      .getAllByCustomerId(this.customer.id)
      .then((concepts) => {
        this.concepts = _.filter(concepts, { archived: false });
      })
      .finally(() => {
        this._loadSpinnerService.stop('mainSpinner');
      });
  }
  goToArticles(concept) {
    this._$state.go('concept.articles', { conceptId: concept.id });
  }
  goToConcept(concept) {
    this._$state.go('concept', { conceptId: concept.id });
  }
  resetTablePosition() {
    this.query.page = 1;
  }
  setGridType(type) {
    this.userSettings.conceptsGridType = type || 'table';
  }
}
angular
  .module('tabs.concepts', [])
  .controller('ConceptsTabController', ConceptsTabController);
