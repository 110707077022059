import { IconButton, useEditableControls } from '@chakra-ui/react';
import { Icons } from './Icons';

export function EditableControls() {
  const { getEditButtonProps } = useEditableControls();

  return (
    <IconButton
      size="sm"
      variant="no-bg"
      icon={<Icons.Rename />}
      {...getEditButtonProps()}
    />
  );
}
