import { Flex, Heading, ScaleFade, Text } from '@chakra-ui/react';
import { ColorData } from '@texas/api/endpoints/compositionApi';
import { moveDownRotateAnimation } from '@texas/resources/animations/animations';
import { rudholmMatrixColors } from '@texas/resources/colors/rudholmColors';
import { Icons, defaultIconSize } from '../Icons';
import { ColorComponent } from './ColorComponent';
import { useTranslation } from 'react-i18next';
import { onlyUnique } from '@texas/utils/helpers/commonHelpers';
import { ColorType } from '@texas/api/endpoints/metadata/colorsApi';

export function RecommendedColors({
  matrixNodeValue,
  pickedColors,
  colorPicked,
}: {
  matrixNodeValue?: string;
  pickedColors: ColorData[];
  colorPicked: (color: ColorData) => void;
}) {
  const { t } = useTranslation();
  const codes = matrixNodeValue?.replace(/\D/g, '').match(/.{1,2}/g) ?? [];
  const foundColorIndices = codes
    .map((c) =>
      rudholmMatrixColors.colors.findIndex(
        (x) => x.code === c && !pickedColors.some((p) => p.code === x.code),
      ),
    )
    .filter((x) => x !== -1)
    .filter(onlyUnique);

  const show = matrixNodeValue !== undefined && foundColorIndices.length > 0;

  return (
    <ScaleFade initialScale={0.9} in={show}>
      <Flex
        flexDir="column"
        gap={2}
        bg="texas.bg.800"
        _light={{ bg: 'gray.10' }}
        borderRadius="md"
        padding={4}
      >
        <Flex gap={2} align="center">
          <Icons.ThumbUp
            animation={moveDownRotateAnimation(1000)}
            boxSize={defaultIconSize}
          />
          <Heading fontSize="md">{t('colors.recommended')}</Heading>
        </Flex>
        <Text>{t('colors.recommendedDesc')}</Text>
        {show && (
          <>
            {foundColorIndices.map((i) => (
              <ColorComponent
                key={i}
                color={rudholmMatrixColors.colors[i]}
                onSelect={() =>
                  colorPicked({
                    code: rudholmMatrixColors.colors[i].code,
                    colorType: ColorType.RudholmMatrix,
                    description: '',
                    hex: rudholmMatrixColors.colors[i].hex,
                    name: rudholmMatrixColors.colors[i].name,
                  })
                }
                selected={false}
              />
            ))}
          </>
        )}
      </Flex>
    </ScaleFade>
  );
}
