import { Flex, Heading, useDisclosure } from '@chakra-ui/react';
import { compositionApi } from '@texas/api/endpoints/compositionApi';
import { FileLink, filesApi } from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { clientEndpoints } from '@texas/clientEndpoints';
import { FileDropzone } from '@texas/components/shared/dropzone/FileDropzone';
import { ImageDropzone } from '@texas/components/shared/dropzone/ImageDropzone';
import { maxSize } from '@texas/components/shared/dropzone/shared';
import {
  acceptedImageFormats,
  imageExtensions,
} from '@texas/utils/helpers/filesHelper';
import { useEffect, useCallback } from 'react';
import { request } from '@texas/utils/helpers/httpHelpers';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { FileBrowserModal } from '@texas/components/shared/files/FileBrowserModal';
import { useTranslation } from 'react-i18next';
import { compositionEvents } from '@texas/components/shared/composition/events';

interface Props {
  compositionId: number;
  fileId?: number;
  folderId: number;
}

export function CompositionImage({ compositionId, folderId, fileId }: Props) {
  const {
    data: fetchedFile,
    set: setFile,
    refetch,
  } = useApiResource(filesApi.getFileLink);
  const { request: updateRequest } = useApiRequest(compositionApi.updateFile);
  const { t } = useTranslation();

  const {
    isOpen: isFileBrowserOpen,
    onClose: onFileBrowserClose,
    onOpen: onFileBrowserOpen,
  } = useDisclosure();

  useEffect(() => {
    if (fileId) refetch(fileId);
    else setFile(null);
  }, [fileId, refetch, setFile]);

  const updateFile = useCallback(
    (file: FileLink) => {
      request(updateRequest, [compositionId, file.id], (_) => {
        setFile(file);
        compositionEvents.fileUpdated.dispatch();
      });
    },
    [compositionId, setFile, updateRequest],
  );
  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateFile, maxSize);

  const {
    isOpen: isRemoveFileOpen,
    onClose: onRemoveFileClose,
    onOpen: onRemoveFileOpen,
  } = useDisclosure();

  return (
    <>
      <Flex direction="column" gap={2}>
        <Heading fontSize="md">{t('fileBrowser.image')}</Heading>
        {fetchedFile ? (
          <ImageDropzone
            imageId={fetchedFile.id}
            imageIdentifier={fetchedFile.identifier}
            imageSrc={clientEndpoints.previewImage(fetchedFile.identifier, 256)}
            allowEdit={true}
            onUpload={(files) => uploadFiles(files, folderId)}
            isUploading={isUploading}
            uploadFailed={uploadFailed}
            fileUploads={fileUploads}
            abortFileUpload={abortFileUpload}
            onRemovefileOpen={onRemoveFileOpen}
            onFileBrowserOpen={onFileBrowserOpen}
            imageArchived={fetchedFile.archived}
          />
        ) : (
          <FileDropzone
            accept={acceptedImageFormats}
            isUploading={isUploading}
            uploadFailed={uploadFailed}
            fileUploads={fileUploads}
            abortFileUpload={abortFileUpload}
            onUpload={(files) => uploadFiles(files, folderId)}
            onFileBrowserOpen={onFileBrowserOpen}
          />
        )}
      </Flex>
      <VerifyDialogWithRequest
        headerTitle={t('fileBrowser.removeImage')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionApi.deleteFile}
        args={[compositionId]}
        isOpen={isRemoveFileOpen}
        onClose={onRemoveFileClose}
        onPerformed={() => {
          setFile(null);
          compositionEvents.fileUpdated.dispatch();
        }}
        onSuccessTitle={t('fileBrowser.fileRemoved')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
      <FileBrowserModal
        rootFolderId={folderId}
        mode="Select"
        isOpen={isFileBrowserOpen}
        onClose={onFileBrowserClose}
        extensionFilter={imageExtensions}
        onSelect={(file: FileLink) => {
          updateFile(file);
          onFileBrowserClose();
        }}
      />
    </>
  );
}
